
import { Vue, Component, Prop } from 'vue-property-decorator';
import { Action } from 'vuex-class';
import { SUPPORTED_LOCALES } from '@/utils/constants';
import Cookies from 'js-cookie';
import getBrowserLocale from '@/utils/i18n-helpers';
import GlobeIcon from 'vue-feather-icons/icons/GlobeIcon';
const clickaway = require('vue-clickaway').mixin;

const namespace: string = 'auth';

@Component<LocaleSwitcher>({
	components: {
		GlobeIcon
	},
	mixins: [ clickaway ]
})

export default class LocaleSwitcher extends Vue {
	@Action('setLocale', { namespace }) private setLocale!: (locale: string) => void;
	@Prop({ type: Boolean, required: false, default: false }) private abbrLocale!: boolean;
	@Prop({ type: String, required: false, default: 'left' }) private align!: 'left' | 'right';

	private supportedLocales: Array<{ value: string, label: string }> = SUPPORTED_LOCALES;
	private opened: boolean = false;

	private get dropdownLabel(): string | undefined {
		if (this.abbrLocale) {
			return this.$i18n.locale.substring(0,2).toUpperCase()
		}
		else if (!this.abbrLocale && this.supportedLocales) {
			return this.supportedLocales.find(locale => locale.value === this.$i18n.locale)?.label;
		}
	}

	/**
	 * Check if browser locale matches any of the supported locales and move it to the top of the list
	 *
	 * @return {Array<{ value: string, label: string }>}
	 */
	private get localesList(): Array<{ value: string, label: string }> {
		const tempLocales = [...this.supportedLocales];
		const localeIndex = tempLocales.findIndex(locale => locale.value === getBrowserLocale());

		if (localeIndex > 0) {
			const locale = tempLocales.splice(localeIndex, 1)[0];
        	tempLocales.unshift(locale);
		}
		return tempLocales;
	}

	/**
	 * Close the dropdown on clickaway
	 *
	 * @return {void}
	 */
	private away(): void {
		if(this.opened) {
			this.opened = false;
		}
	}

	/**
	 * Select the correct locale and force reload for the changes to be applied in
	 * the vuex store and utils. We save the locale in the cookies/localstorage to use it on reload
	 * If the locale selected is the same as the current one, we don't reload the page
	 *
	 * @param {string} value
	 * @return {void}
	 */
	private selectLocale(locale: string): void {
		const currentLocale = Cookies.get('locale') || localStorage.getItem('locale') || null;
		if (currentLocale !== locale) {
			this.setLocale(locale);
			window.location.reload();
		}
		this.opened = false;
	}
}
