
import { Component, Prop, Vue } from 'vue-property-decorator';
import { Getter } from 'vuex-class';
import { DateTime } from 'luxon';
import { capitalize } from 'lodash';
import DailyMenu from '@/components/menu/rotational-menus/DailyMenu.vue';
import FiltersBar from '@/components/filter/FiltersBar.vue';
import LoadingSpinner from '@/components/shared/LoadingSpinner.vue';
import LocaleSwitcher from './navigation/LocaleSwitcher.vue';
import MenuResultsHeader from '@/components/menu/MenuResultsHeader.vue';
import Sidebar from '@/components/navigation/Sidebar.vue';
import TabBar from '@/components/navigation/TabBar.vue';
import TextMenu from '@/components/menu/TextMenu.vue';

const namespace: string = 'filters';

@Component<RotationalMenuResults>({
	components: {
		DailyMenu,
		FiltersBar,
		LoadingSpinner,
		LocaleSwitcher,
		MenuResultsHeader,
		Sidebar,
		TabBar,
		TextMenu
	}
})
export default class RotationalMenuResults extends Vue {
	@Getter('getSelectedDiets', { namespace }) private selectedDiets!: string[];
	@Getter('getSelectedAllergens', { namespace }) private selectedAllergens!: string[];
	@Getter('getRestaurant', { namespace: 'restaurant' }) private restaurant!: Restaurant;
	@Getter('getRotationalMenusFetching', { namespace: 'restaurant' }) private rotationalMenusFetching!: boolean;
	@Prop({ type: Array, required: true }) private rotationalMenus!: RotationalMenu[];
	@Prop({ type: Boolean, required: false, default: false }) private orderPaused!: boolean;
	@Prop({ type: String, required: false, default: 'vertical' }) private cardStyle!: string;
	@Prop({ type: String, required: false, default: '' }) private restaurantDisplayName!: string;
	@Prop({ type: String, required: false, default: '' }) private restaurantImage!: string;

	/**
	 * Format rotational menus to work with the sidebar
	 * Techinally it expects an array of menus or sections, but it only uses name, id and slug properties
	 *
	 * @return {{[key: string]: string; }[]}
	 */
	private get formattedSidebarElements(): { [key: string]: string; }[] {
		return this.rotationalMenus.map((rotationalMenu: RotationalMenu) => {
			const day = capitalize(DateTime.fromISO(rotationalMenu.date).setLocale(this.$i18n.locale).toFormat('EEEE'));
			const date = DateTime.fromISO(rotationalMenu.date).setLocale(this.$i18n.locale).toFormat('DD');

			return {
				name: `${day} <br class="date-breakline"> (${date})`,
				id: `main-divider-${rotationalMenu.date}`,
				slug: rotationalMenu.date
			};
		});
	}

	/**
	 * Filter out rotational menus that have no menus (no items added for the day)
	 *
	 * @return {RotationalMenu[]}
	 */
	private get filteredRotationalMenus(): RotationalMenu[] {
		return this.rotationalMenus.filter((rotationalMenu: RotationalMenu) => {
			return rotationalMenu.menus.length > 0;
		});
	}

	private get imageURL(): string {
		if (process.env.NODE_ENV === 'development') {
			return `${this.restaurantImage}`;
		}
		return `${process.env.VUE_APP_IMAGE_BUCKET}/${this.restaurantImage}`;
	}
}
