
import { Vue, Component } from 'vue-property-decorator';
import { Getter } from 'vuex-class';
import { fireGoogleTag } from '@/utils/google-tag-manager-helpers';
import AccountIcon from '../../assets/images/icons/manage-account.svg?inline';

@Component<ProfileButton>({
	components: {
		AccountIcon
	}
})
export default class ProfileButton extends Vue {
	@Getter('isAuthenticated', { namespace: 'auth' }) private isAuthenticated!: boolean;

	/**
	 * Redirect to login or profile page depending if user is authenticated or not
	 *
	 * @return {void}
	 */
	private redirectToProfileOrLogin(): void {
		fireGoogleTag({ name: 'viewProfile', userType: this.isAuthenticated ? 'loggedIn' : 'guest' });
		this.$router.push({ path: this.isAuthenticated ? '/profile' : '/login', query: this.$route.query }).catch(() => {});
	}
}
