
import { Vue, Component } from 'vue-property-decorator';
import { Action, Getter } from 'vuex-class';
import { en, fr } from 'vuejs-datepicker/dist/locale';
import { ValidationProvider } from 'vee-validate';
import { DateTime } from 'luxon';
import { fireGoogleTag } from '@/utils/google-tag-manager-helpers';
import { scrollIntoViewIfNeeded } from '@/utils/helpers';
import { addNoticeAndIntervalTimeToCurrentDate, findNextEnabledDate, generateTimeSeries, setDisabledDaysForDatePicker, isTimeSelectable } from '@/utils/scheduling';
import ClockIcon from 'vue-feather-icons/icons/ClockIcon';
import CalendarIcon from 'vue-feather-icons/icons/CalendarIcon';
import Datepicker from 'vuejs-datepicker';
import '@/validation-rules';

const namespace: string = 'cart';
const DEFAULT_SUITES_INFORMATION = {
	tabLimit: null,
	deliveryTime: '',
	notes: ''
};

@Component<CheckoutPreOrderDeliveryInformation>({
	components: {
		ValidationProvider,
		Datepicker,
		ClockIcon,
		CalendarIcon
	}
})
export default class CheckoutPreOrderDeliveryInformation extends Vue {
	@Action('fetchSchedulingRestrictions', { namespace }) private fetchSchedulingRestrictions!: (items: OrderItem[]) => ScheduleRestrictions;
	@Getter('getSuitesInformation', { namespace }) private suitesInformation!: CheckoutSuitesInfo | null;
	@Getter('getItems', { namespace }) private items!: OrderItem[];
	@Getter('getUserEventSuite', { namespace: 'suites' }) private userEventSuite!: EventSuite | null;
	@Getter('getZone', { namespace: 'restaurant' }) private zone!: string;

	private fetchingRestrictions: boolean = false;
	private timeOptions: string[] = [];
	private maxedOutIntervals: DateTime[] = [];
	private orderInterval: number = 0;
	private requiredNotice: number = 0;
	private weeklyAvailabilities: (SchedulingRestrictionMenuAvailability | null)[] = [];
	private holidayHours: HolidayHours[] = [];
	private disabledDates: object = {};
	private datepickerLocales: object = {
		en,
		fr
	};
	private suitesInfo: CheckoutSuitesInfo = { ...DEFAULT_SUITES_INFORMATION };
	private suitesPreOrderDeliveryDateTimeInfo: { date: string; time: string; } = {
		date: '',
		time: ''
	};

	private get preOrderDeliveryTimeOverlaps(): boolean {
		if(this.userEventSuite && this.userEventSuite.event.pre_order_delivery_time_setting?.type === 'datetime') {
			return DateTime.fromISO(this.userEventSuite.event.pre_order_delivery_time_setting.details.start_date!).toFormat('dd-MMM-yyyy') !== DateTime.fromISO(this.userEventSuite.event.pre_order_delivery_time_setting.details.end_date!).toFormat('dd-MMM-yyyy');
		}
		return false;
	}

	private get preOrderSuitesDeliveryTimes(): VueSelectOption[] | null {
		if(this.userEventSuite && this.userEventSuite.event.pre_order_delivery_time_setting?.type === 'basic') {
			return this.userEventSuite.event.pre_order_delivery_time_setting.details.values!.map(value => {
				return {
					label: this.$t(`checkout.form.suites.pre_order_delivery_time_settings.basic.pre_order_delivery_time_${value}`),
					code: value,
				};
			});
		}
		return null;
	}

	/**
	 * Set the suites info on load and set the delivery date/time values
	 * if the user selected any before. Then we fetch the scheduling restrictions.
	 *
	 * @return {Promise<void>}
	 */
	private async mounted(): Promise<void> {
		if(this.suitesInformation) {
			this.suitesInfo = this.suitesInformation;
		}

		// Set the delivery day/time from the previous selection if any.
		if(this.userEventSuite && this.userEventSuite.event.pre_order_delivery_time_setting?.type === 'datetime') {
			if(this.suitesInfo.deliveryTime && DateTime.fromISO(this.suitesInfo.deliveryTime!).isValid) {
				const deliveryTime: DateTime = DateTime.fromISO(this.suitesInfo.deliveryTime!);
				this.suitesPreOrderDeliveryDateTimeInfo.date = deliveryTime.toISO();
				this.suitesPreOrderDeliveryDateTimeInfo.time = deliveryTime.toFormat(this.$t('checkout.form.suites.pre_order_delivery_time_settings.datetime.time_format'));
			}
			else {
				this.suitesPreOrderDeliveryDateTimeInfo.date = DateTime.fromISO(this.userEventSuite.event.pre_order_delivery_time_setting.details.start_date!).toISO();
			}
			await this.getSchedulingRestrictions();
		}
	}

	/**
	 * Get all the scheduling restrictions from the menu/restaurant
	 * and generate the time series with the interval.
	 *
	 * @return {Promise<void>}
	 */
	private async getSchedulingRestrictions(): Promise<void> {
		try {
			this.fetchingRestrictions = true;
			const scheduleRestrictions: ScheduleRestrictions = await this.fetchSchedulingRestrictions(this.items);
			this.orderInterval = scheduleRestrictions.max_order_interval;
			this.maxedOutIntervals = scheduleRestrictions.maxed_out_intervals.map((dateString: string) => DateTime.fromISO(dateString));
			this.requiredNotice = scheduleRestrictions.required_notice;
			this.holidayHours = scheduleRestrictions.holiday_hours;
			this.setWeeklyAvailabilities(scheduleRestrictions.weekly_availabilities);
			this.setDisabledDates();
			this.timeOptions = generateTimeSeries(this.suitesPreOrderDeliveryDateTimeInfo.date, this.weeklyAvailabilities, this.orderInterval, this.userEventSuite!);
		} catch(e) {
			this.$toasted.show(this.$t('checkout.form.takeout.error_fetching_scheduling_information'), { type: 'error', position: 'top-center' }).goAway(5000);
		} finally {
			setTimeout(() => {
				this.fetchingRestrictions = false;
			}, 150);
		}
	}

	/**
	 * Set the weekly availabilities with the interval to prevent ordering
	 * at the opening time. We have to check that the availability does not overlap
	 * with the interval added.
	 *
	 * @param {(SchedulingRestrictionMenuAvailability | null)[]} tempWeeklyAvailabilities
	 * @return {void}
	 */
	private setWeeklyAvailabilities(tempWeeklyAvailabilities: (SchedulingRestrictionMenuAvailability | null)[]): void {
		tempWeeklyAvailabilities.forEach(tempAvailability => {
			if(tempAvailability) {
				tempAvailability.start = DateTime.fromFormat(tempAvailability.start, 'hh:mm').plus({ minutes: this.orderInterval ? this.orderInterval : 0 }).toFormat('HH:mm');
				return this.weeklyAvailabilities.push(tempAvailability);
			}
			else {
				return this.weeklyAvailabilities.push(null);
			}
		});
	}

	/**
	 * Disable past dates, disable days that have no availability
	 * and push the current date to another day if the weekday does
	 * not have an availability.
	 *
	 * @return {void}
	 */
	private setDisabledDates(): void {
		if(!this.suitesInformation?.deliveryTime) {
			this.suitesPreOrderDeliveryDateTimeInfo.date = addNoticeAndIntervalTimeToCurrentDate(this.suitesPreOrderDeliveryDateTimeInfo.date, this.requiredNotice, this.orderInterval);
		}

		const result: { disabledDays: number[], holidayClosedDates: Date[], date: string } = setDisabledDaysForDatePicker(this.weeklyAvailabilities, this.holidayHours, this.suitesPreOrderDeliveryDateTimeInfo.date, !!this.suitesInformation?.deliveryTime, null, this.userEventSuite);
		const disabledDays: number[] = result.disabledDays;
		const holidayClosedDates: Date[] = result.holidayClosedDates;
		this.suitesPreOrderDeliveryDateTimeInfo.date = result.date;

		if (disabledDays.length < 7) {
			this.suitesPreOrderDeliveryDateTimeInfo.date = findNextEnabledDate(this.suitesPreOrderDeliveryDateTimeInfo.date, disabledDays, holidayClosedDates, this.zone || 'America/Toronto');
		}
		this.setDisabledDatesOutsidePreOrderTime(disabledDays, holidayClosedDates);
	}

	/**
	 * Disable past dates that are outside of the pre order time
	 *
	 * @param {number[]} disabledDays
	 * @return {void}
	 */
	private setDisabledDatesOutsidePreOrderTime(disabledDays: number[], holidayClosedDates: Date[]): void {
		let range: { to: Date; from: Date; } = { to: new Date(), from: new Date() };
		range.to = DateTime.fromISO(this.userEventSuite!.event.pre_order_delivery_time_setting.details.start_date!).toJSDate();
		range.from = DateTime.fromISO(this.userEventSuite!.event.pre_order_delivery_time_setting.details.end_date!).toJSDate();

		// We don't want the range to start on a disabled day (make sure that there is at least one day available to prevent infinite loop)
		while(!this.suitesInformation?.deliveryTime && disabledDays.length < 7 && disabledDays.includes(DateTime.fromJSDate(range.to).weekday)) {
			range.to = DateTime.fromJSDate(range.to).plus({ day: 1 }).toJSDate();
		}

		// Don't set the selected date if the date was already selected, if not, set it.
		if(!this.suitesInformation?.deliveryTime) {
			this.updatePreOrderDeliveryDate(range.to);
			this.updateSuitesDateTimeDeliveryTime();
		}

		this.disabledDates = {
			to: range.to,
			from: range.from,
			days: disabledDays,
			dates: holidayClosedDates
		};
	}

	/**
	 * When opening the time dropdown, checks every time interval if it is
	 * selectable or not based on required_notice, current time, intervals,
	 * availabilities and more.
	 *
	 * @param {string} time
	 * @return {boolean}
	 */
	private isSelectable(time: string): boolean {
		return isTimeSelectable(time, this.suitesPreOrderDeliveryDateTimeInfo.date, this.requiredNotice, this.maxedOutIntervals, this.weeklyAvailabilities, null, this.userEventSuite!);
	}

	/**
	 * Triggers smooth scroll to the closest available time when
	 * opening the timepicker
	 *
	 * @return {void}
	 */
	private opened(): void {
		Vue.nextTick(() => {
			const selected: Element | null = document.querySelector('#takeout-time-picker .vs__dropdown-menu .vs__dropdown-option--selected');
			if (selected) {
				scrollIntoViewIfNeeded(selected, { behavior: 'smooth', block: 'end', inline: 'start' });
			}
			else {
				const firstSelectable: Element | null = document.querySelector('#takeout-time-picker .vs__dropdown-menu li:not(.vs__dropdown-option--disabled)');
				firstSelectable && scrollIntoViewIfNeeded(firstSelectable, { behavior: 'smooth', block: 'end', inline: 'start' });
			}
		});
	}

	/**
	 * Update the suites delivery basic time and send the event to parent
	 *
	 * @param {string} deliveryTime
	 * @return {void}
	 */
	private updateSuitesBasicDeliveryTime(deliveryTime: string): void {
		this.suitesInfo.deliveryTime = deliveryTime;
		this.updateSuitesInfo();
	}

	/**
	 * Set selected Date to ISO string since the DatePicker returns
	 * a date object by default
	 *
	 * @param {Date} date
	 * @return {void}
	 */
	private updatePreOrderDeliveryDate(date: Date): void {
		this.suitesPreOrderDeliveryDateTimeInfo.date = DateTime.fromJSDate(date).toISO()!;
		this.updateSuitesDateTimeDeliveryTime();
	}

	/**
	 * Update the suites delivery date/time by formatting the date and time
	 * from our local object to the deliveryTime string as an ISO string.
	 *
	 * @return {void}
	 */
	private updateSuitesDateTimeDeliveryTime(): void {
		this.suitesInfo.deliveryTime = DateTime.fromFormat(`${this.suitesPreOrderDeliveryDateTimeInfo.date!.split('T')[0]} ${this.suitesPreOrderDeliveryDateTimeInfo.time}`, this.$t('checkout.form.takeout.timepicker_selectable_format')).toISO()!;
		this.updateSuitesInfo();
		this.timeOptions = generateTimeSeries(this.suitesPreOrderDeliveryDateTimeInfo.date, this.weeklyAvailabilities, this.orderInterval, this.userEventSuite!);
	}

	/**
	 * Send event to update the suites info to the parent
	 *
	 * @return {void}
	 */
	private updateSuitesInfo(): void {
		fireGoogleTag({ name: 'suitesDeliveryTimeSelected', specifier: this.suitesInfo.deliveryTime });
		this.$emit('input', this.suitesInfo);
	}
}
