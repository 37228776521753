
import { Vue, Component } from 'vue-property-decorator';
import { Getter } from 'vuex-class';
import CartItemList from '@/components/cart/CartItemList.vue';
import CartOrderList from '@/components/cart/CartOrderList.vue';
import CartPricing from '@/components/cart/CartPricing.vue';
import EditIcon from '@/assets/images/icons/edit.svg?inline';

const namespace: string = 'cart';

@Component<CheckoutReviewOrderDetails>({
	components: {
		CartItemList,
		CartOrderList,
		CartPricing,
		EditIcon
	}
})
export default class CheckoutReviewOrderDetails extends Vue {
	@Getter('getItems', { namespace }) private items!: MenuItem;
	@Getter('getRotationalMenuOrders', { namespace }) private rotationalMenuOrders!: RotationalMenuOrder[];
	@Getter('getSubtotal', { namespace }) private subtotal!: string;
	@Getter('getPromoDiscount', { namespace }) private promoDiscount!: string;
	@Getter('getMemberDiscount', { namespace }) private memberDiscount!: string;
	@Getter('getServiceCharge', { namespace }) private serviceCharge!: string | null;
	@Getter('getDeliveryCharge', { namespace }) private deliveryCharge!: string | null;
	@Getter('getTaxes', { namespace }) private taxes!: string;
	@Getter('getTotal', { namespace }) private total!: string;
	@Getter('getTip', { namespace }) private tip!: string;
	@Getter('getVoucherAmount', { namespace }) private voucherAmount!: string;
	@Getter('getTotalWithTip', { namespace }) private totalWithTip!: string;
	@Getter('isK12Location', { namespace: 'restaurant' }) private isK12Location!: boolean;

	/**
	 * Send event to the parent to switch to the respective
	 * screen depending on what change button was clicked
	 * (IE: payment info)
	 *
	 * @param {number} index - screen index
	 * @return {void}
	 */
	private switchTo(index: number): void {
		this.$emit('switchTo', index);
	}
}
