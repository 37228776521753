
import { Vue, Component, Prop } from 'vue-property-decorator';
import { Action, Getter } from 'vuex-class';
import { en, fr } from 'vuejs-datepicker/dist/locale';
import { ValidationProvider } from 'vee-validate';
import { DateTime } from 'luxon';
import { scrollIntoViewIfNeeded } from '@/utils/helpers';
import { findNextEnabledDate, generateTimeSeries, isTimeSelectable, setDisabledDaysForDatePicker } from '@/utils/scheduling';
import ClockIcon from 'vue-feather-icons/icons/ClockIcon';
import CalendarIcon from 'vue-feather-icons/icons/CalendarIcon';
import Datepicker from 'vuejs-datepicker';
import '@/validation-rules';

const namespace: string = 'cart';
const DEFAULT_PICKUP_INFORMATION = {
	scheduled: false,
	dueByDate: '',
	dueByTime: '',
	notes: '',
	prepTime: 0
};

@Component<CheckoutTakeoutInformation>({
	components: {
		ValidationProvider,
		Datepicker,
		ClockIcon,
		CalendarIcon
	}
})
export default class CheckoutTakeoutInformation extends Vue {
	@Prop({ type: Boolean, required: true, default: false }) private isTakeOut!: boolean;
	@Action('setTodayDate', { namespace }) private setTodayDate!: (date: string) => void;
	@Action('setDateSelectedByUserFlag', { namespace }) private setDateSelectedByUserFlag!: (value: boolean) => void;
	@Action('fetchSchedulingRestrictions', { namespace }) private fetchSchedulingRestrictions!: (items: OrderItem[]) => ScheduleRestrictions;
	@Getter('isDelivery', { namespace }) private isDelivery!: boolean;
	@Getter('isGenericCatering', { namespace }) private isGenericCatering!: boolean;
	@Getter('getPickupInformation', { namespace }) private pickupInformation!: CheckoutPickupInfo | null;
	@Getter('getItems', { namespace }) private items!: OrderItem[];
	@Getter('getToday', { namespace }) private today!: string;
	@Getter('getTakeoutDateRange', { namespace }) private takeoutDateRange!: string;
	@Getter('getTakeoutSpecificDates', { namespace }) private takeoutSpecificDates!: string;
	@Getter('getDateSelectedByUser', { namespace }) private dateAlreadySelected!: boolean;
	@Getter('getTenantId', { namespace: 'restaurant' }) private tenantId!: string;
	@Getter('noTakeoutTimeslot', { namespace: 'restaurant' }) private noTakeoutTimeslot!: boolean;
	@Getter('hideTakeoutSpecialInstructions', { namespace: 'restaurant' }) private hideTakeoutSpecialInstructions!: boolean;
	@Getter('getZone', { namespace: 'restaurant' }) private zone!: string;
	@Getter('getMaxAdvancedOrderDays', { namespace: 'restaurant' }) private maxAdvancedOrderDays!: number;
	@Getter('getOrderCutoffDate', { namespace: 'restaurant' }) private orderCutoffDate!: DateTime | null;

	private pickup: CheckoutPickupInfo = DEFAULT_PICKUP_INFORMATION;
	private timeOptions: string[] = [];
	private maxedOutIntervals: DateTime[] = [];
	private orderInterval: number = 0;
	private fetchingRestrictions: boolean = true;
	private requiredNotice: number = 0;
	private weeklyAvailabilities: (SchedulingRestrictionMenuAvailability | null)[] = [];
	private holidayHours: HolidayHours[] = [];
	private disabledDates: object = {};
	private datepickerLocales: object = {
		en,
		fr
	};

	// WILL BE REFACTORING ALL OF THIS SOON

	/**
	 * Set the pickup date if it's null and get the scheduling
	 * restrictions from the menus/restaurant.
	 *
	 * @return {Promise<void>}
	 */
	private async mounted(): Promise<void> {
		this.updatePickupInfo();
		if (this.pickupInformation) {
			this.pickup = this.pickupInformation;
			if(!this.pickup.dueByDate) {
				this.setToday();
			}
		} else {
			this.setToday();
		}
		await this.getSchedulingRestrictions();
		this.updateTimepickerQuestions(true);
	}

	private setToday() {
		this.pickup.dueByDate = DateTime.local().toISO()!;
		this.updatePickupInfo();
		this.timeOptions = generateTimeSeries(this.pickup.dueByDate!, this.weeklyAvailabilities, this.orderInterval);
	}

	/**
	 * Set selected Date to ISO string since the DatePicker returns
	 * a date object by default
	 *
	 * @param {Date} date
	 * @return {void}
	 */
	private updatePickupDate(date: Date): void {
		this.setDateSelectedByUserFlag(true);
		this.pickup.dueByDate = DateTime.fromJSDate(date).toISO()!;
		this.timeOptions = generateTimeSeries(this.pickup.dueByDate!, this.weeklyAvailabilities, this.orderInterval);
		this.updateTimepickerQuestions();
	}

	/**
	 * Send event to update the pickup info to the parent
	 *
	 * @return {void}
	 */
	private updatePickupInfo(): void {
		this.$emit('input', this.pickup);
	}

	/**
	 * Get all the scheduling restrictions from the menu/restaurant
	 * and generate the time series with the interval.
	 *
	 * @return {Promise<void>}
	 */
	private async getSchedulingRestrictions(): Promise<void> {
		try {
			this.fetchingRestrictions = true;
			const scheduleRestrictions: ScheduleRestrictions = await this.fetchSchedulingRestrictions(this.items);
			this.orderInterval = scheduleRestrictions.max_order_interval;
			this.maxedOutIntervals = scheduleRestrictions.maxed_out_intervals.map((dateString: string) => DateTime.fromISO(dateString));
			this.requiredNotice = scheduleRestrictions.required_notice;
			this.pickup.scheduled = scheduleRestrictions.scheduled_ordering;
			this.pickup.prepTime = scheduleRestrictions.prep_time;
			this.holidayHours = scheduleRestrictions.holiday_hours;
			this.setWeeklyAvailabilities(scheduleRestrictions.weekly_availabilities);
			this.setDisabledDates();
			this.timeOptions = generateTimeSeries(this.pickup.dueByDate!, this.weeklyAvailabilities, this.orderInterval);
		} catch(e) {
			this.$toasted.show(this.$t('checkout.form.takeout.error_fetching_scheduling_information'), { type: 'error', position: 'top-center' }).goAway(5000);
		} finally {
			setTimeout(() => {
				this.fetchingRestrictions = false;
			}, 150);
		}
	}

	/**
	 * Set the weekly availabilities with the interval to prevent ordering
	 * at the opening time. We have to check that the availability does not overlap.
	 * with the interval added.
	 *
	 * @param {SchedulingRestrictionMenuAvailability[]} tempWeeklyAvailabilities
	 * @return {void}
	 */
	private setWeeklyAvailabilities(tempWeeklyAvailabilities: SchedulingRestrictionMenuAvailability[]): void {
		tempWeeklyAvailabilities.forEach(tempAvailability => {
			if(tempAvailability) {
				tempAvailability.start = DateTime.fromFormat(tempAvailability.start, 'hh:mm').plus({ minutes: this.orderInterval ? this.orderInterval : 0 }).toFormat('HH:mm');
				return this.weeklyAvailabilities.push(tempAvailability);
			}
			else {
				return this.weeklyAvailabilities.push(null);
			}
		});
	}

	/**
	 * Disable past dates, disable days that have no availability
	 * and push the current date to another day if the weekday does
	 * not have an availability.
	 *
	 * @return {void}
	 */
	private setDisabledDates(): void {
		// Add the required notice time and the order interval time to the current date
		if(this.today.length <= 0) {
			this.pickup.dueByDate = DateTime.fromISO(this.pickup.dueByDate!).plus({ minutes: (this.requiredNotice + this.orderInterval) }).toISO()!;
		}

		const result: { disabledDays: number[], holidayClosedDates: Date[], date: string, minDate: Date | null } = setDisabledDaysForDatePicker(this.weeklyAvailabilities, this.holidayHours, this.pickup.dueByDate!, this.today.length <= 0, this.orderCutoffDate);
		const disabledDays: number[] = result.disabledDays;
		const holidayClosedDates: Date[] = result.holidayClosedDates;
		const minDate: Date | null = result.minDate ? result.minDate : DateTime.local().minus({ days: 1 }).toJSDate();
		const maxDate: Date | null = this.maxAdvancedOrderDays ? DateTime.local().plus({ days: this.maxAdvancedOrderDays }).toJSDate() : null;
		this.pickup.dueByDate = result.date;

		if(disabledDays.length < 7) {
			this.pickup.dueByDate = findNextEnabledDate(this.pickup.dueByDate, disabledDays, holidayClosedDates, this.zone || 'America/Toronto');
		}

		// We acknowledge that the check was done once and that we have the
		// minimum date, we prevent doing the check more than once.
		if(this.today.length <= 0) {
			this.setTodayDate(this.pickup.dueByDate!);
		}


		this.setDisableDaysForPicker(disabledDays, holidayClosedDates, minDate, maxDate);
	}

	/**
	 * Set disable days on the variable for the timepicker visibility
	 * If there are dates passed in the query params the range is override
	 * by it. This is a temporary solution that should be cleaned up
	 *
	 * @param {number[]} disabledDays
	 * @param {Date[]} holidayClosedDates
	 * @param {Date | null} maxDate
	 * @return {void}
	 */
	private setDisableDaysForPicker(disabledDays: number[], holidayClosedDates: Date[], minDate: Date, maxDate: Date | null): void {
		// No dates passed in the URL, default flow
		if(!this.takeoutDateRange && !this.takeoutSpecificDates) {
			this.disabledDates = {
				to: minDate,
				from: maxDate,
				days: disabledDays,
				dates: holidayClosedDates
			};
		}

		// Date range passed or specific dates
		else {
			// Date range (dateRange=)
			if(this.takeoutDateRange) {
				this.setDisabledDaysFromTakeoutDateRange(disabledDays);
			}

			// Specific date(s) passed (dates=)
			else if(this.takeoutSpecificDates) {
				this.setDisabledDaysFromTakeoutSpecificDates(disabledDays);
			}
		}
	}

	/**
	 * Set the disabled days from the takeout range passed in the URL
	 *
	 * @param {number[]} disabledDays
	 * @return {void}
	 */
	private setDisabledDaysFromTakeoutDateRange(disabledDays: number[]): void {
		const [toDateString, fromDateString]: string[] = this.takeoutDateRange.split(',');
		const today: DateTime = DateTime.fromISO(this.today).startOf('day');
		let range: { to?: Date, from?: Date } = {};

		// Get the date ranges and format them
		range.to = this.formatURLDate(toDateString);
		range.from = this.formatURLDate(fromDateString);

		// If the TO date is past today, to date becomes current date. If from is not passed in the params, we simply pass undefined.
		if (DateTime.fromJSDate(range.to!).startOf('day').toMillis() < today.toMillis()) {
			range.to = today.toJSDate();
		}
		range.from = range.from && DateTime.fromJSDate(range.from).isValid ? range.from : undefined;

		// We don't want the range to start on a disabled day (make sure that there is at least one day available to prevent infinite loop)
		while(!this.dateAlreadySelected && disabledDays.length < 7 && disabledDays.includes(DateTime.fromJSDate(range.to!).weekday)) {
			range.to = DateTime.fromJSDate(range.to!).plus({ day: 1 }).toJSDate();
		}

		// Valid TO date, If there is a FROM date, we make sure they dont overlap (FROM date being earlier than TO date)
		if((range.to && DateTime.fromJSDate(range.to).isValid) && (range.from ? DateTime.fromJSDate(range.to).startOf('day').toMillis() <= DateTime.fromJSDate(range.from).startOf('day').toMillis() : true)) {

			// Don't set the selected date if the date was already selected
			if(!this.dateAlreadySelected) {
				this.updatePickupDate(range.to);
				this.updatePickupInfo();
			}

			this.disabledDates = {
				to: range.to,
				from: range.from,
				days: disabledDays
			};
		}

		// Invalid date range, we go back to default
		else {
			this.disabledDates = {
				to: today.minus({ days: 1 }).toJSDate(),
				days: disabledDays
			};
		}
	}

	/**
	 * Set disabled days for any takeout specific dates that was passed in the URL
	 *
	 * @param {number[]} disabledDays
	 * @return {void}
	 */
	private setDisabledDaysFromTakeoutSpecificDates(disabledDays: number[]): void {
		const dateStrings: string[] = this.takeoutSpecificDates.split(',');
		const today: DateTime = DateTime.fromISO(this.today).startOf('day');
		let dates: Date[] = [];

		// Validate the dates passed in the parameters. Make sure the format is correct,
		// that the date is valid and not in the past.
		dateStrings.forEach(dateString => {
			const date: Date | undefined = this.formatURLDate(dateString);
			const dateTime: DateTime = DateTime.fromJSDate(date!);
			if (dateTime.isValid && dateTime.toMillis() >= today.toMillis() && !disabledDays.includes(dateTime.weekday)) {
				dates.push(date!);
			}
		});

		if (dates.length) {
			// Sort by earliest date
			dates.sort((a: Date, b: Date) => DateTime.fromJSDate(a).toMillis() - DateTime.fromJSDate(b).toMillis());

			this.disabledDates = {
				// Custom function to pass in the datepicker component. Returns true if date is disabled.
				customPredictor: (date: any) => !dates.some(specificDate => DateTime.fromJSDate(date).toISODate() === DateTime.fromJSDate(specificDate).toISODate()),
				to: today.minus({ days: 1 }).toJSDate(),
				days: disabledDays
			};

			// Don't set the date if the date was already selected
			if (!this.dateAlreadySelected) {
				this.updatePickupDate(dates[0]);
				this.updatePickupInfo();
			}
		}
		else {
			// No valid days, back to default
			this.disabledDates = {
				to: today.minus({ days: 1 }).toJSDate(),
				days: disabledDays
			};
		}
	}

	/**
	 * Format the date range passed in the URL
	 *
	 * @param {string} dateString
	 * @return {Date | undefined}
	 */
	private formatURLDate(dateString: string): Date | undefined {
		const dateOptions: string[] = dateString.split('-');
		if(dateOptions.length === 3) {
			return DateTime.fromFormat(`${dateOptions[0]}/${dateOptions[1]}/${dateOptions[2]}`, 'd/M/yyyy').toJSDate();
		}
		return undefined;
	}

	/**
	 * When opening the time dropdown, checks every time interval if it is
	 * selectable or not based on required_notice, current time, intervals,
	 * availabilities and more.
	 *
	 * @param {string} time
	 * @return {boolean}
	 */
	private isSelectable(time: string): boolean {
		return isTimeSelectable(time, this.pickup.dueByDate!, this.requiredNotice, this.maxedOutIntervals, this.weeklyAvailabilities, this.orderCutoffDate);
	}

	/**
	 * Triggers smooth scroll to the closest available time when
	 * opening the timepicker
	 *
	 * @return {void}
	 */
	private opened(): void {
		Vue.nextTick(() => {
			const selected = document.querySelector('#takeout-time-picker .vs__dropdown-menu .vs__dropdown-option--selected');
			if (selected) {
				scrollIntoViewIfNeeded(selected, { behavior: 'smooth', block: 'end', inline: 'start' });
			}
			else {
				const firstSelectable = document.querySelector('#takeout-time-picker .vs__dropdown-menu li:not(.vs__dropdown-option--disabled)');
				firstSelectable && scrollIntoViewIfNeeded(firstSelectable, { behavior: 'smooth', block: 'end', inline: 'start' });
			}
		});
	}

	/**
	 * Format estimated_prep_time gathered from the menu to display
	 * the time to the user.
	 *
	 * @return {void}
	 */
	private getPrepTimeString(prep_time: number) {
		return prep_time < 60 || !this.pickup?.prepTime ? `${prep_time}min` : `${Math.floor(prep_time / 60)}h ${prep_time % 60}min`;
	}

	/**
	 * Update the timepicker questions (time options and selected time) whenever we update the pickup date/time.
	 *
	 * @param {boolean} [isFirstLoad]
	 * @return {void}
	 */
	private updateTimepickerQuestions(isFirstLoad?: boolean): void {
		this.$emit('update-timepicker-questions', isFirstLoad);
	}
}
