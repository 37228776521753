
import { Vue, Component, Prop } from 'vue-property-decorator';
import { Action, Getter } from 'vuex-class';
import CheckoutTakeoutInformation from './form/TakeoutInformation.vue';
import CheckoutDeliveryInformation from './form/DeliveryInformation.vue';
import CheckoutPreOrderDeliveryInformation from './form/PreOrderDeliveryInformation.vue';
import CheckoutCustomQuestions from './form/CustomQuestions.vue';
import CheckoutContactInformation from './form/ContactInformation.vue';
import CheckoutPaymentInformation from './form/PaymentInformation.vue';
import CheckoutTabInformation from './form/TabInformation.vue';
import PoweredByApp8 from '@/components/shared/PoweredByApp8.vue';
import InfoBanner from '@/components/shared/InfoBanner.vue';
import LockIcon from 'vue-feather-icons/icons/LockIcon';
import { ValidationObserver } from 'vee-validate';
import { Validate } from '@/types';
import { DateTime } from 'luxon';

const namespace: string = 'cart';

const DEFAULT_PICKUP_INFORMATION = {
	scheduled: false,
	dueByDate: '',
	dueByTime: '',
	notes: ''
};

const DEFAULT_SUITES_INFORMATION = {
	tabLimit: null,
	deliveryTime: '',
	notes: ''
};

const DEFAULT_CONTACT_INFORMATION = {
	full_name: '',
	email: '',
	phone_number: '',
	password: '',
};

const DEFAULT_CARD_INFORMATION = {
	number: '',
	cvd: '',
	expiry_date: '',
	postal_code: '',
	type: ''
};

const DEFAULT_DELIVERY_INFORMATION = {
	type: ''
};

@Component<CheckoutForm>({
	components: {
		CheckoutTakeoutInformation,
		CheckoutDeliveryInformation,
		CheckoutPreOrderDeliveryInformation,
		CheckoutCustomQuestions,
		CheckoutContactInformation,
		CheckoutPaymentInformation,
		CheckoutTabInformation,
		LockIcon,
		ValidationObserver,
		PoweredByApp8,
		InfoBanner
	}
})
export default class CheckoutForm extends Vue {
	@Prop({ type: Boolean, required: true, default: false }) private gPayReady!: boolean;
	@Prop({ type: Boolean, required: true, default: false }) private aPayReady!: boolean;
	@Prop({ type: Boolean, required: true, default: false }) private isPreOrdering!: boolean;
	@Action('reviewButtonClicked', { namespace }) private reviewButtonClicked!: () => void;
	@Action('updatePickupInformation', { namespace }) private updatePickupInformation!: (pickup: CheckoutPickupInfo) => void;
	@Action('updateSuitesInformation', { namespace }) private updateSuitesInformation!: (suitesInfo: CheckoutSuitesInfo) => void;
	@Action('updateDeliveryInformation', { namespace }) private updateDeliveryInformation!: (delivery: CheckoutDeliveryInfo) => void;
	@Action('updateContactInformation', { namespace }) private updateContactInformation!: (contact: CheckoutContactInfo) => void
	@Action('updateCardInformation', { namespace }) private updateCardInformation!: (payment: CheckoutCardInfo) => void;
	@Action('updateInvoice', { namespace }) private updateInvoice!: (invoice: boolean) => void;
	@Action('updateCostCenter', { namespace }) private updateCostCenter!: (costCenter: string | null) => void;
	@Action('updatePurchaseOrder', { namespace }) private updatePurchaseOrder!: (purchaseOrder: string | null) => void;
	@Action('updateCustomQuestions', { namespace }) private updateCustomQuestions!: (questions: CheckoutCustomQuestion[]) => void;
	@Action('addPaymentMethod', { namespace: 'auth' }) private addPaymentMethod!: (payload: AddPaymentMethodPayload) => Promise<void>;
	@Action('doesUserExist', { namespace: 'auth' }) private doesUserExist!: (email: string) => Promise<boolean>;
	@Getter('isTakeOut', { namespace }) private isTakeOut!: boolean;
	@Getter('isPickupOnly', { namespace }) private isPickupOnly!: boolean;
	@Getter('isDelivery', { namespace }) private isDelivery!: boolean;
	@Getter('isGenericCatering', { namespace }) private isGenericCatering!: boolean;
	@Getter('getPickupInformation', { namespace }) private pickupInformation!: CheckoutPickupInfo | null;
	@Getter('getSuitesInformation', { namespace }) private suitesInformation!: CheckoutSuitesInfo | null;
	@Getter('getDeliveryInformation', { namespace }) private deliveryInformation!: CheckoutDeliveryInfo | null;
	@Getter('getContactInformation', { namespace }) private contactInformation!: CheckoutContactInfo | null;
	@Getter('getCardInformation', { namespace }) private cardInformation!: CheckoutCardInfo;
	@Getter('getInvoiceInformation', { namespace }) private invoiceInformation!: boolean;
	@Getter('getCostCenterInformation', { namespace }) private costCenterInformation!: string | null;
	@Getter('getPurchaseOrderInformation', { namespace }) private purchaseOrderInformation!: string | null;
	@Getter('getPaymentMethod', { namespace }) private paymentMethod!: string;
	@Getter('getCheckoutAnsweredQuestions', { namespace }) private checkoutAnsweredQuestions!: CheckoutCustomQuestion[] | null;
	@Getter('getQueueInfo', { namespace }) private queueInfo!: QueueInfo | null;
	@Getter('isAnonymousUser', { namespace: 'auth' }) private isAnonymousUser!: boolean;
	@Getter('getUser', { namespace: 'auth' }) private user!: UserInfo;
	@Getter('getCreditCards', { namespace: 'auth' }) private creditCards!: SavedPaymentOption[];
	@Getter('getRestaurantPaymentProcessor', { namespace: 'restaurant' }) private restaurantPaymentProcessor!: string;
	@Getter('getRestaurantDeliveryInfo', { namespace: 'restaurant' }) private restaurantDeliveryInfo!: DefaultDelivery | StadiumDelivery | CustomDelivery;
	@Getter('getCustomQuestions', { namespace: 'restaurant' }) private customQuestions!: CustomQuestion[];
	@Getter('isK12Location', { namespace: 'restaurant' }) private isK12Location!: boolean;

	$refs!: { observer: Validate, customQuestions: CheckoutCustomQuestions }

	private pickup: CheckoutPickupInfo = DEFAULT_PICKUP_INFORMATION;
	private suitesInfo: CheckoutSuitesInfo = DEFAULT_SUITES_INFORMATION;
	private contact: CheckoutContactInfo = DEFAULT_CONTACT_INFORMATION;
	private delivery: CheckoutDeliveryInfo = DEFAULT_DELIVERY_INFORMATION;
	private paymentCard: CheckoutCardInfo = DEFAULT_CARD_INFORMATION;
	private invoice: boolean = false;
	private costCenter: string | null = null;
	private purchaseOrder: string | null = null;
	private answeredQuestions: CheckoutCustomQuestion[] = [];
	private coordinatesErrorMsg: string = '';
	private infoBannerMessage: string = '';
	private isValidating: boolean = false;
	private showInfoBanner: boolean = false;

	private get showDelivery(): boolean {
		return this.restaurantDeliveryInfo && ((this.isTakeOut && this.isDelivery && !this.isPickupOnly) || this.isGenericCatering || this.isK12Location)
	}

	/**
	 * Set information on component mount if there is any
	 * to fill up the fields if they were filled up previously
	 *
	 * @return {void}
	 */
	private mounted(): void {
		if(this.pickupInformation) {
			this.pickup = this.pickupInformation;
		}
		if(this.suitesInformation) {
			this.suitesInfo = this.suitesInformation;
		}
		if(this.deliveryInformation) {
			this.delivery = this.deliveryInformation;
		}
		if(this.contactInformation) {
			this.contact = this.contactInformation;
		}
		if(this.checkoutAnsweredQuestions?.length) {
			this.answeredQuestions = [ ...this.checkoutAnsweredQuestions ];
		}

		// Payment info
		if(this.invoiceInformation) {
			this.invoice = this.invoiceInformation;
		}
		else if(this.costCenterInformation) {
			this.costCenter = this.costCenterInformation;
		}
		else if(this.purchaseOrderInformation) {
			this.purchaseOrder = this.purchaseOrderInformation;
		}
		else if(this.cardInformation) {
			this.paymentCard = this.cardInformation;
		}

		if(typeof this.queueInfo?.minutesLeft === 'number' && typeof this.queueInfo?.queueSize === 'number') {
			this.showInfoBanner = true;
			this.infoBannerMessage = this.$t('checkout.form.takeout.queue_info', { minutes: this.queueInfo.minutesLeft, orders: this.queueInfo.queueSize });
		}
	}

	/**
	 * Update pickup info on input changed
	 *
	 * @param {CheckoutPickupInfo} pickup
	 * @return {void}
	 */
	private updateTempPickupInfo(pickup: CheckoutPickupInfo): void {
		this.pickup = pickup;
	}

	/**
	 * Update suites info on input changed
	 *
	 * @param {CheckoutSuitesInfo} suitesInfo
	 * @return {void}
	 */
	private updateTempSuitesInfo(suitesInfo: CheckoutSuitesInfo): void {
		this.suitesInfo.deliveryTime = suitesInfo.deliveryTime;
		this.suitesInfo.notes = suitesInfo.notes;
	}

	/**
	 * Update tab limit in suites info
	 *
	 * @param {number} tabLimit
	 * @returns {void}
	 */
	private updateTabInfo(tabLimit: number): void {
		this.suitesInfo.tabLimit = tabLimit;
	}

	/**
	 * Update contact info on input changed
	 *
	 * @param {CheckoutContactInfo} contact
	 * @return {void}
	 */
	private updateTempContactInfo(contact: CheckoutContactInfo): void {
		this.contact = contact;
	}

	/**
	 * Update payment info on input changed
	 *
	 * @param {CheckoutCardInfo} tempPaymentCard
	 * @return {void}
	 */
	private updateTempPaymentCardInfo(tempPaymentCard: CheckoutCardInfo): void {
		this.paymentCard = tempPaymentCard;
	}

	/**
	 * Update invoice on input changed
	 *
	 * @param {boolean} tempInvoice
	 * @return {void}
	 */
	private updateTempInvoice(tempInvoice: boolean): void {
		this.invoice = tempInvoice;
	}

	/**
	 * Update cost center on input changed
	 *
	 * @param {string | null} tempCostCenter
	 * @return {void}
	 */
	private updateTempCostCenter(tempCostCenter: string | null): void {
		this.costCenter = tempCostCenter;
	}

	/**
	 * Update purchase order on input changed
	 *
	 * @param {string | null} tempPurchaseOrder
	 * @return {void}
	 */
	private updateTempPurchaseOrder(tempPurchaseOrder: string | null): void {
		this.purchaseOrder = tempPurchaseOrder;
	}

	/**
	 * Update delivery info on input changed
	 *
	 * @param {CheckoutDeliveryInfo} payment
	 * @return {void}
	 */
	private updateTempDeliveryInfo(delivery: CheckoutDeliveryInfo): void {
		this.coordinatesErrorMsg = '';
		this.delivery = delivery;
	}

	/**
	 * Update custom questions on input changed
	 *
	 * @param {CheckoutCustomQuestion[]} questions
	 * @return {void}
	 */
	private updateTempCustomQuestions(questions: CheckoutCustomQuestion[]): void {
		this.answeredQuestions = questions;
		this.answeredQuestions.length && this.updateCustomQuestions(this.answeredQuestions);
	}

	/**
	 * Detect which card type it is with regex
	 *
	 * @param {string} cardNumber
	 * @return {void}
	 */
	private findCardType(cardNumber: string): void {
		const amex_regex = new RegExp('^3[47][0-9]{0,}$'); // American Express: 34, 37
		const visa_regex = new RegExp('^4[0-9]{0,}$'); // Visa: 4
		const mc_regex = new RegExp('^(5[1-5]|222[1-9]|22[3-9]|2[3-6]|27[01]|2720)[0-9]{0,}$'); // Mastercard: 2221-2720, 51-55
		if(cardNumber.match(amex_regex)) {
			this.paymentCard.type = 'AMEX';
		}
		if(cardNumber.match(mc_regex)) {
			this.paymentCard.type = 'MC';
		}
		if(cardNumber.match(visa_regex)) {
			this.paymentCard.type = 'VISA';
		}
	}

	/**
	 * Calculates great-circle distances between two points using the
	 * harversine formula (keep in mind this is in a "crow" distance,
	 * meaning it does not count the obstacles in the way of the point
	 * A and B). The haversine formula also does not account for the earth
	 * not being perfectly spheroid, meaning there is a margin of error,
	 * but since this is used for a approximate distance, the margin is
	 * acceptable. If we want to make this even better, we could even use spherical
	 * geometry from GMaps API.
	 * For more info on the formula, see: https://en.wikipedia.org/wiki/Haversine_formula
	 *
	 * @param {number} lat1
	 * @param {number} lng1
	 * @param {number} lat2
	 * @param {number} lng2
	 * @return {number}
	 */
	private getDistanceFromLatLonInKm(lat1: number, lng1: number, lat2: number, lng2: number): number {
		const earthRadiusInKilometer = 6371;
		const dLat = this.degreeToRad(lat2 - lat1);
		const dLon = this.degreeToRad(lng2 - lng1);

		// Square of half the chord length between the points
		const squareChordLength =
			Math.sin(dLat / 2) * Math.sin(dLat / 2) +
			Math.cos(this.degreeToRad(lat1)) * Math.cos(this.degreeToRad(lat2)) *
			Math.sin(dLon / 2) * Math.sin(dLon / 2);

		// Angular distance in radians.
		var angularDistance = 2 * Math.atan2(Math.sqrt(squareChordLength), Math.sqrt(1 - squareChordLength));
		var distanceInKilometer = earthRadiusInKilometer * angularDistance;
		return distanceInKilometer;
	}

	/**
	 * Takes a degree value and translates it to
	 * a radian. Opted out of using the Number prototype toRad as
	 * it was being inconsistant in some cases.
	 *
	 * @param {number} degree
	 * @return {number}
	 */
	private degreeToRad(degree: number): number {
		return degree * (Math.PI / 180);
	}

	/**
	 * Validate all the input fields, save the pickup information if it's
	 * a take out order and save the user contact information if it's
	 * an anonymous order and proceed to the review screen.
	 *
	 * @return {Promise<void>}
	 */
	private async saveAndContinue(): Promise<any> {
		this.isValidating = true;
		this.reviewButtonClicked();
		let isValid = await this.$refs.observer.validate();
		let deliveryValid = true;

		// Validate delivery radius
		if(this.pickup.delivery) {
			deliveryValid = this.validateDelivery();
		}

		// Validate that the user is not trying to use an email that is already in use when creating an account
		if (this.contactInformation?.password && await this.doesUserExist(this.contactInformation.email)) {
			this.$refs.observer.setErrors({
				email: [this.$t('checkout.form.contact.email_unique_error')]
			});
			isValid = false;
		}

		// If a payment method isn't chosen, we do not go next (unless it's pay on premise, then we don't care)
		if(this.restaurantPaymentProcessor !== 'payOnPremise' && !this.paymentMethod) {
			setTimeout(() => {
				const requiredLabel = document.getElementById('payment-method-header') as HTMLElement;
				requiredLabel.scrollIntoView({ behavior: 'smooth', block: 'start', inline: 'nearest' });
			}, 250);
			this.isValidating = false;
			return;
		}

		// If everything is validated, we can go next.
		if (isValid && deliveryValid) {
			this.coordinatesErrorMsg = '';
			this.setDueDateTimeIfAsapOrder();
			this.updatePickupInformation(this.pickup);
			this.updateDeliveryInformation(this.delivery);

			// Update suites info if pre-ordering
			if(this.isPreOrdering) {
				this.updateSuitesInformation(this.suitesInfo);
			}

			// If anon user, update its contact information
			if(this.isAnonymousUser) {
				this.updateContactInformation(this.contact);
			}

			// If the anon user or WBL user with no card added a credit card, find its type and update
			if (this.paymentMethod === 'credit_card' && (this.isAnonymousUser || !this.creditCards.length)) {
				this.findCardType(this.paymentCard.number.replace(/\D/g,''));
				this.updateCardInformation(this.paymentCard);
			}

			if(this.paymentMethod === 'invoice') {
				this.updateInvoice(true);
			}
			else if(this.paymentMethod === 'cost_center') {
				this.updateCostCenter(this.costCenter);
			}
			else if(this.paymentMethod === 'purchase_order') {
				this.updatePurchaseOrder(this.purchaseOrder);
			}

			this.$emit('next');
		}
		else {
			setTimeout(() => {
				const requiredLabel = document.getElementsByClassName('error-label')[0] as HTMLElement;
				requiredLabel.scrollIntoView({ behavior: 'smooth', block: 'start', inline: 'nearest' });
			}, 250);
		}
		this.isValidating = false;
	}

	/**
	 * Set the due date and time for an asap order
	 *
	 * @return {void}
	 */
	private setDueDateTimeIfAsapOrder(): void {
		if ((this.isTakeOut || this.isGenericCatering) && !this.pickup.scheduled) {
			this.pickup.dueByDate = DateTime.local().toISO()!;
			this.pickup.dueByTime = DateTime.local().toFormat(this.$t('checkout.confirmation.time_format'));
			if (this.pickup.dueByTime.length == 7) {
				this.pickup.dueByTime = '0' + this.pickup.dueByTime;
			}
		}
	}

	/**
	 * Validate delivery address
	 *
	 * @return {boolean}
	 */
	private validateDelivery(): boolean {
		const tempDelivery: CheckoutDeliveryInfo = this.delivery as CheckoutDeliveryInfo;
		this.coordinatesErrorMsg = '';
		if(tempDelivery.type === 'address') {
			if(tempDelivery.lat && tempDelivery.lng) {
				if(tempDelivery.streetNumber) {
					const defaultDelivery: DefaultDelivery = this.restaurantDeliveryInfo;
					let distance = this.getDistanceFromLatLonInKm(tempDelivery.lat, tempDelivery.lng, defaultDelivery.lat!, defaultDelivery.lng!);
					if(distance > tempDelivery.radius!) {
						this.coordinatesErrorMsg = this.$t('checkout.form.delivery.error_address_exceed_radius');
						return false;
					}
					return true;
				}
				else {
					this.coordinatesErrorMsg = this.$t('checkout.form.delivery.error_address_not_precise');
					return false;
				}
			}
			else {
				this.coordinatesErrorMsg = this.$t('checkout.form.delivery.error_address_invalid');
				return false;
			}
		}
		else if(tempDelivery.type === 'stadium') {
			if(tempDelivery.section && tempDelivery.row && tempDelivery.seat) {
				return true;
			}
		}
		else if(tempDelivery.type === 'school') {
			if(tempDelivery.grade && tempDelivery.classroom && tempDelivery.student) {
				return true;
			}
		}
		else {
			if(tempDelivery.location && tempDelivery.area) {
				return true;
			}
		}
		return false;
	}

	/**
	 * Update the timepicker questions (time options and selected time) whenever we update the pickup date/time.
	 *
	 * @param {boolean|undefined} isFirstLoad
	 * @return {void}
	 */
	private updateTimepickerQuestions(isFirstLoad: boolean|undefined): void {
		this.$refs.customQuestions?.updateTimepickerQuestions(isFirstLoad);
	}
}
