
import { Component, Prop, Vue } from 'vue-property-decorator';
import { Getter } from 'vuex-class';
import { VueMasonryPlugin } from 'vue-masonry';
import { DateTime } from 'luxon';
import { capitalize } from 'lodash';
import { filterItemsWithAllergensAndDiets } from '@/utils/filters';
import MenuItemViewer from '@/components/menu/MenuItemViewer.vue';
import Modal from '@/components/shared/Modal.vue';
import TextMenu from '@/components/menu/TextMenu.vue';

Vue.use(VueMasonryPlugin);

@Component<DailyMenu>({
	components: {
		MenuItemViewer,
		Modal,
		TextMenu
	}
})
export default class DailyMenu extends Vue {
	@Getter('isViewOnly', { namespace: 'cart' }) private isViewOnly!: boolean;
	@Prop({ type: Object, required: true }) private rotationalMenu!: RotationalMenu;
	@Prop({ type: Number, required: true }) private menuIndex!: number;
	@Prop({ type: String, required: false, default: 'vertical' }) private cardStyle!: string;
	@Prop({ type: Boolean, required: false, default: false }) private orderPaused!: boolean;
	@Prop({ type: Array, required: false, default: () => [] }) private selectedDiets!: string[];
	@Prop({ type: Array, required: false, default: () => [] }) private selectedAllergens!: string[];

	private currentMenuItemId: number | null = null;
	private showDetails: boolean = false;

	private get formattedMenuDate(): string {
		const formattedDate = capitalize(DateTime.fromISO(this.rotationalMenu.date).setLocale(this.$i18n.locale).toFormat('EEEE (DDD)'));

		// Capitalize the month for English
		if (this.$i18n.locale.toLowerCase().includes('en')) {
			return formattedDate.replace(/\((\w)/, (match: string, p1: string) => `(${p1.toUpperCase()}`);
		}

		return formattedDate;
	}

	/**
	 * Filters menu items based on selected diets and allergens
	 *
	 * @return {Menu[]}
	 */
	private get filteredMenus(): Menu[] {
		if (!this.selectedAllergens.length && !this.selectedDiets.length) {
			return this.rotationalMenu.menus;
		}
		return this.rotationalMenu.menus.map(menu => {
			return {
				...menu,
				items: filterItemsWithAllergensAndDiets(this.selectedDiets, this.selectedAllergens, menu.items)
			};
		});
	}

	/**
	 * Opens the selected card's menu item viewer
	 *
	 * @param {MenuItem} menuItem
	 * @return {void}
	 */
	private viewMenuItem(menuItem: MenuItem): void {
		this.currentMenuItemId = menuItem.id;
		this.showDetails = true;
		document.documentElement.classList.add('modal-open');
	}

	/**
	 * Hide the menu viewer modal
	 *
	 * @return {void}
	 */
	private hideMenuItemModal(): void {
		this.showDetails = false;
		this.currentMenuItemId = null;
		document.documentElement.classList.remove('modal-open');
	}
}
