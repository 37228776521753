
import { Vue, Component, Prop } from 'vue-property-decorator';
import { Action, Getter } from 'vuex-class';
import { updateQuantityExceedLimit } from '@/utils/helpers';
import XIcon from 'vue-feather-icons/icons/XIcon';
import Modal from '@/components/shared/Modal.vue';
import InputModalContainer from '@/components/shared/InputModalContainer.vue';
import CartQuantityAmount from '@/components/cart/CartQuantityAmount.vue';
const namespace: string = 'cart';

@Component<CartItem>({
	components: {
		XIcon,
		Modal,
		InputModalContainer,
		CartQuantityAmount
	}
})

export default class CartItem extends Vue {
	@Prop({ type: Object, required: true, default: () => {} }) private item!: OrderItem;
	@Prop({ type: Boolean, required: true, default: false }) private canEdit!: boolean;
	@Prop({ type: String, required: false }) private orderDate!: string;
	@Action('removeFullItemFromCart', { namespace }) private removeFullItemFromCart!: (payload: { menuItem: MenuItem, orderDate?: string }) => void;
	@Action('updateQuantity', { namespace }) private updateQuantity!: (quantityObject: any) => void;
	@Getter('getMaxItemsPerCart', { namespace: 'restaurant' }) private maxItemsPerCart!: number;

	private itemId: number = 0;
	private quantity: number = 0;
	private opened: boolean = false;
	private loading: boolean = false;

	/**
	 * This deals with the visual for price not rolling up
	 * IE reduce the item's price with the options price depending
	 * if they roll up or not
	 *
	 * @param {OrderItem} item
	 * @return {string}
	 */
	private visualItemPrice(item: OrderItem): string {
		let visualItemPrice: number = item.memberOrderPrice !== undefined ? +item.memberOrderPrice : +item.orderPrice!;
		if(item.orderOptions && item.orderOptions.length) {

			for (let oIndex = 0; oIndex < item.orderOptions.length; oIndex++) {
				const orderOption: OrderOptionGroup = item.orderOptions[oIndex];

				for (let vIndex = 0; vIndex < orderOption.values!.length; vIndex++) {
					const value: OrderOption = orderOption.values![vIndex];
					const optionQuantity = value.quantity || 1;
					// If the option doesn't roll up we need to remove the amount from the item's price
					if(!value.roll_up) {
						visualItemPrice = +(+visualItemPrice - (((value.memberPrice !== undefined ? +value.memberPrice : +value.price!) * optionQuantity) * item.quantity)).toFixed(2);
					}

					// Sub options
					if(value.options && value.options.length) {
						for (let subOIndex = 0; subOIndex < value.options.length; subOIndex++) {
							const subOrderOption: OrderOptionGroup = value.options[subOIndex];
							for (let subVIndex = 0; subVIndex < subOrderOption.values!.length; subVIndex++) {
								const subValue: OrderOption = subOrderOption.values![subVIndex];
								if((!value.roll_up && !subValue.roll_up) || (value.roll_up && !subValue.roll_up) || (!value.roll_up && subValue.roll_up)) {
									visualItemPrice = +(+visualItemPrice - (((subValue.memberPrice !== undefined ? +subValue.memberPrice : +subValue.price!) * optionQuantity) * item.quantity)).toFixed(2);
								}
							}
						}
					}
				}
			}
		}
		return visualItemPrice.toFixed(2);
	}

	/**
	 * This deals with the visual for price not rolling up
	 * If the option price does not roll up we need to show its price
	 * on the item's option list
	 *
	 * @param {Option} value
	 * @param {OrderOptionGroup} orderOption
	 * @param {OrderItem} item
	 * @return {string}
	 */
	private visualOptionPrice(value: Option, orderOption: OrderOptionGroup, item: OrderItem): string {
		let visualOptionPrice: number = value.memberPrice !== undefined ? +value.memberPrice : +value.price!;

		if(!value.roll_up) {
			if(value.options && value.options.length) {
				if(value.options && value.options.length) {
					for (let subOIndex = 0; subOIndex < value.options.length; subOIndex++) {
						const subOrderOption: OptionGroup = value.options[subOIndex];
						for (let subVIndex = 0; subVIndex < subOrderOption.values!.length; subVIndex++) {
							const subValue: Option = subOrderOption.values![subVIndex];
							if(subValue.roll_up) {
								visualOptionPrice = (+visualOptionPrice + (subValue.memberPrice !== undefined ? +subValue.memberPrice : +subValue.price!));
							}
						}
					}
				}
			}
		}

		return (visualOptionPrice * item.quantity).toFixed(2);
	}

	/**
	 * Open an input modal to edit the clicked item's quantity
	 *
	 * @param {number} id
	 * @param {number} quantity
	 * @return {void}
	 */
	private editItemQuantity(id: number, quantity: number): void {
		if(this.canEdit) {
			this.itemId = id;
			this.quantity = quantity;
			this.opened = true;
		}
	}

	/**
	 * Hide the edit input modal
	 *
	 * @return {void}
	 */
	private hideInputModal(): void {
		this.opened = false;
	}

	/**
	 * Remove item from cart
	 *
	 * @param {OrderItem} item
	 * @return {Promise<void>}
	 */
	private async removeItemFromCart(item: OrderItem): Promise<void> {
		try {
			this.loading = true;
			await this.removeFullItemFromCart({ menuItem: item, orderDate: this.orderDate });
		}
		catch (error) {
			this.$toasted.show(this.$t('cart.item_list.error_remove_item_from_cart'), { type: 'error', position: 'top-center' }).goAway(5000);
		}
		finally {
			this.loading = false;
		}
		this.opened = false;
	}

	/**
	 * Update the item's quantity
	 *
	 * @param {{ id: number, quantity: string }} payload
	 * @return {Promise<void>}
	 */
	private async setQuantity(payload: { id: number, quantity: string }): Promise<void> {
		try {
			this.loading = true;
			if (!updateQuantityExceedLimit(payload)) {
				await this.updateQuantity({ ...payload, orderDate: this.orderDate });
			}
			else {
				this.$toasted.show(this.$t('cart.item_list.error_quantity_limit_exceeded', { maxItemsPerCart: this.maxItemsPerCart }), { type: 'error', position: 'top-center' }).goAway(5000);
			}
		}
		catch (error) {
			this.$toasted.show(this.$t('cart.item_list.error_quantity_update'), { type: 'error', position: 'top-center' }).goAway(5000);
		}
		finally {
			this.loading = false;
		}
		this.opened = false;
	}
}
