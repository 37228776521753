
import { Vue, Component, Prop } from 'vue-property-decorator';
import { DateTime } from 'luxon';
import { capitalize } from 'lodash';
import CartItem from '@/components/cart/CartItem.vue';

@Component<CartOrderList>({
	components: {
		CartItem
	}
})

export default class CartOrderList extends Vue {
	@Prop({ type: Array, required: true, default: () => [] }) private orders!: RotationalMenuOrder[];
	@Prop({ type: Boolean, required: true, default: false }) private canEditItems!: boolean;

	private formatDate(date: string): string {
		const formattedDate = capitalize(DateTime.fromISO(date).setLocale(this.$i18n.locale).toFormat('EEEE (DD)'));

		// Capitalize the month for English
		if (this.$i18n.locale.toLowerCase().includes('en')) {
			return formattedDate.replace(/\((\w)/, (match: string, p1: string) => `(${p1.toUpperCase()}`);
		}

		return formattedDate;
	}
}
