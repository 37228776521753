
import { Vue, Component, Prop, Watch } from 'vue-property-decorator';
import { Action, Getter } from 'vuex-class';
import { addToCartExceedLimit } from '@/utils/helpers';
import i18n from '@/i18n';
import XIcon from 'vue-feather-icons/icons/XIcon';
import ChevronLeftIcon from 'vue-feather-icons/icons/ChevronLeftIcon';
import ChevronRightIcon from 'vue-feather-icons/icons/ChevronRightIcon';
import ItemInformation from '@/components/menu/viewer/ItemInformation.vue';
import AddOns from '@/components/menu/viewer/AddOns.vue';
import Modal from '@/components/shared/Modal.vue';
import ErrorValidationInformation from '@/components/shared/ErrorValidationInformation.vue';
import QuantitySelector from '@/components/menu/viewer/QuantitySelector.vue';

const clickaway = require('vue-clickaway').mixin;
const namespace: string = 'cart';
const ITEM_STATES = {
	sold_out: i18n.t('menu.item_viewer.btn_add_to_cart_sold_out'),
	unavailable: i18n.t('menu.item_viewer.btn_add_to_cart_unavailable'),
	dine_in_only: i18n.t('menu.item_viewer.btn_add_to_cart_dine_in_only'),
	ordering_unavailable: i18n.t('menu.item_viewer.btn_add_to_cart_ordering_unavailable'),
	add_to_order: i18n.t('menu.item_viewer.btn_add_to_cart')
};

@Component<MenuItemViewer>({
	components: {
		XIcon,
		ChevronLeftIcon,
		ChevronRightIcon,
		ItemInformation,
		AddOns,
		Modal,
		ErrorValidationInformation,
		QuantitySelector
	},
	mixins: [ clickaway ]
})
export default class MenuItemViewer extends Vue {
	@Prop({ type: Array, required: true, default: () => [] }) private menuItems!: MenuItem[];
	@Prop({ type: Object, required: false }) private orderAgainItem!: MenuItem|null;
	@Prop({ type: Number, required: false, default: 0 }) private initialId!: number|null;
	@Prop({ type: Boolean, required: true, default: false }) private isViewOnly!: boolean;
	@Prop({ type: Boolean, required: true, default: false }) private orderPaused!: boolean;
	@Prop({ type: String, required: false, default: '' }) private orderDate!: string;
	@Prop({ type: Boolean, required: false, default: undefined }) private acceptsTakeout!: boolean;
	@Prop({ type: Boolean, required: false, default: true }) private showArrows!: boolean;
	@Prop({ type: Boolean, required: false, default: false }) private isFeaturedItem!: boolean;
	@Prop({ type: Boolean, required: false, default: false }) private isUpsellItem!: boolean;
	@Action('setTemporaryItem', { namespace }) private setTemporaryItem!: (temporaryItemPayload: { tempItem: MenuItem|null, quantity?: number }) => void;
	@Action('addToCart', { namespace }) private addToCart!: (extraData: any) => void;
	@Action('addToRotationalMenuCart', { namespace }) private addToRotationalMenuCart!: (extraData: any) => void;
	@Action('updateValidationError', { namespace }) private updateValidationError!: (error: object) => void;
	@Action('addTemporaryItem', { namespace }) private addTemporaryItem!: (menuItem: MenuItem) => void;
	@Action('removeTemporaryItem', { namespace }) private removeTemporaryItem!: (menuItem: MenuItem) => void;
	@Getter('getOrderPrice', { namespace }) private orderPrice!: string;
	@Getter('getMemberOrderPrice', { namespace }) private memberOrderPrice!: string|undefined;
	@Getter('getLegalCheck', { namespace }) private legalChecked!: boolean;
	@Getter('getTemporaryOrderOptions', { namespace }) private temporaryOrderOptions!: OrderOptionGroup[] | null;
	@Getter('getTemporaryQuantity', { namespace }) private quantity!: number;
	@Getter('isTakeOut', { namespace }) private isTakeOut!: boolean;
	@Getter('getOrderType', { namespace }) private getOrderType!: (detailed: boolean) => string;
	@Getter('isAnonymousUser', { namespace: 'auth' }) private isAnonymousUser!: boolean;
	@Getter('getSearchInput', { namespace: 'search' }) private searchInput!: string;
	@Getter('getMaxItemsPerCart', { namespace: 'restaurant' }) private maxItemsPerCart!: number;
	@Getter('orderingRequiresLogin', { namespace: 'restaurant' }) private orderingRequiresLogin!: boolean;
	@Watch('menuItem')
	onItemsChanged() {
		this.checkIfImageExists();
	}
	// Todo: refactor once we refactor the addons/subaddons completely (could easily apply recursivity here)
	@Watch('temporaryOrderOptions', { deep: true })
	onTemporaryOrderOptionsChanged() {
		// Reset the calorie count beforehand
		this.calories = this.menuItem?.calories ? this.menuItem.calories : 0;
		this.addonsCalories = 0;

		if(this.temporaryOrderOptions && this.temporaryOrderOptions.length) {

			// Addons
			this.temporaryOrderOptions.forEach((temporaryOrderOption: OrderOptionGroup) => {

				if(temporaryOrderOption.values && temporaryOrderOption.values.length) {
					temporaryOrderOption.values.forEach((value: OrderOption) => {
						const optionQuantity = value.quantity ? value.quantity : 1;

						if(temporaryOrderOption.optionGroupType === 'pricing') {
							this.calories = value.calories ? value.calories : this.menuItem?.calories;
						}
						else {
							this.addonsCalories += value.calories ? (value.calories * optionQuantity): 0;
						}

						// Sub addons
						if(value.options && value.options.length) {
							value.options.forEach((subTemporaryOrderOption: OrderOptionGroup) => {

								if(subTemporaryOrderOption.values && subTemporaryOrderOption.values.length) {
									subTemporaryOrderOption.values.forEach((subValue: OrderOption) => {
										this.addonsCalories += subValue.calories ? (subValue.calories * optionQuantity) : 0;
									});
								}
							});
						}
					});
				}
			});
		}

		// If the addons calories are at 0, and
		this.calories = Number.isInteger(this.menuItem?.calories) || this.addonsCalories ? this.calories + this.addonsCalories : null;

		const interval = setInterval(() => {
			if(Number.isInteger(this.calories)) {
				if(this.displayCalories !== this.calories) {
					let change = (this.calories! - this.displayCalories) / 10;
					change = change >= 0 ? Math.ceil(change) : Math.floor(change);
					this.displayCalories = this.displayCalories! + change;
				}
				else {
					clearInterval(interval);
				}
			}
			else {
				this.displayCalories = 0;
				clearInterval(interval);
			}
		}, 50);
	}

	// Error modal properties
	private errorModalOpened: boolean = false;
	private errorModalShowSecondButton: boolean = false;
	private errorModalEvents: string[] = ['close'];
	private errorModalButtons: string[] = i18n.t('menu.item_viewer.error_validation_dialog_buttons') as unknown as string[];
	private requiredOptionsFilled: boolean = false;
	private showErrorMessages: boolean = false;
	private processing: boolean = false;
	private showImage: boolean = true;
	private legalCheckRequired: boolean = false;
	private specialInstructions: string = '';
	private calories: number|null|undefined = this.menuItem?.calories;
	private displayCalories: number = this.calories ? this.calories : 0;
	private addonsCalories: number = 0;
	private xDown: number | null = null;
	private yDown: number | null = null;
	private currentId: number|null = null;
	private ITEM_STATES: object = {};
	private currentItemState: string = '';

	private get imageURL(): string {
		if (process.env.NODE_ENV === 'development') {
			return `${this.menuItem!.image}`;
		}
		return `${process.env.VUE_APP_IMAGE_BUCKET}/${this.menuItem!.image}`;
	}

	/**
	 * Get the menu item
	 *
	 * @return {MenuItem | null}
	 */
	private get menuItem(): MenuItem|null {
		if (this.orderAgainItem) {
			return this.orderAgainItem;
		}
		if (this.menuItems) {
			const menuItem: MenuItem|undefined = this.currentId ? this.menuItems.find((i: MenuItem) => i.id === this.currentId) : this.menuItems.find((i: MenuItem) => i.id === this.initialId);
			if (menuItem) {
				return menuItem;
			}
		}
		return null;
	}

	/**
	 * Create variable to access the constant throughout the
	 * Vue template
	 *
	 * @return {void}
	 */
	private created(): void {
		this.setTemporaryItem({ tempItem: this.menuItem });
		this.ITEM_STATES = ITEM_STATES;
		this.checkIfImageExists();
	}

	/**
	 * Set the temp item to whatever item is being viewed.
	 * Add touch listeners for swipe events.
	 *
	 * @return {void}
	 */
	private mounted() {
		this.setItemState(this.menuItem!);
		document.addEventListener('touchstart', this.handleTouchStart, false);
		document.addEventListener('touchmove', this.handleTouchMove, false);
	}

	/**
	 * Remove touch listeners
	 *
	 * @return {void}
	 */
	private beforeDestroy(): void {
		document.removeEventListener('touchstart', this.handleTouchStart);
		document.removeEventListener('touchmove', this.handleTouchMove);
	}

	/**
	 * Check if the image exists or is broken, if it's broken
	 * we remove the image from the DOM.
	 *
	 * @return {void}
	 */
	private checkIfImageExists() {
		if(this.menuItem && this.menuItem.image) {
			let img = new Image();
			let vm = this;
			img.onerror = () => {
				vm.showImage = false;
			};
			img.onload = () => {
				vm.showImage = true;
			};
			img.src = this.imageURL;
		}
	}

	/**
	 * Update the item state that will be used to determine
	 * the button state if ordering is available or not.
	 * Goes from biggest priority to lowest.
	 *
	 * @param {MenuItem}
	 * @return {void}
	 */
	private setItemState(menuItem: MenuItem): void {
		if(menuItem.sold_out) {
			this.currentItemState = ITEM_STATES.sold_out;
		}
		else if(menuItem.unavailable) {
			this.currentItemState = ITEM_STATES.unavailable;
		}
		else if(this.isTakeOut && this.acceptsTakeout === false) {
			this.currentItemState = ITEM_STATES.dine_in_only;
		}
		else if(this.orderPaused) {
			this.currentItemState = ITEM_STATES.ordering_unavailable;
		}
		else {
			this.currentItemState = ITEM_STATES.add_to_order;
		}
	}

	/**
	 * Set the temp item to whatever item is being viewed.
	 * Add touch listeners for swipe events.
	 *
	 * @return {void}
	 */
	private updateSpecialInstructions(specialInstructions: string) {
		this.specialInstructions = specialInstructions;
	}

	/**
	 * Checks the first touch of the user for swipe listen
	 *
	 * @return {void}
	 */
	private handleTouchStart(e: TouchEvent): void {
		const firstTouch = e.touches[0];
		this.xDown = firstTouch.clientX;
		this.yDown = firstTouch.clientY;
	}

	/**
	 * Listen to swipe event to switch to prev/next item
	 *
	 * @param {TouchEvent} e
	 * @return {void}
	 */
	private handleTouchMove(e: TouchEvent): void {
		if(!this.searchInput && this.showArrows) {
			if (!this.xDown || !this.yDown) {
				return;
			}

			const xUp = e.touches[0].clientX;
			const yUp = e.touches[0].clientY;
			const xDiff = this.xDown - xUp;
			const yDiff = this.yDown - yUp;

			if (Math.abs(xDiff) > Math.abs(yDiff)) {
				if (xDiff > 10) {
					/* left swipe */
					this.prevMenuItem();
				}
				else if (xDiff < -10) {
					/* right swipe */
					this.nextMenuItem();
				}
			}
			this.xDown = null;
			this.yDown = null;
		}
	}

	/**
	 * Go to next menu item
	 *
	 * @return {void}
	 */
	private nextMenuItem(): void {
		const currentIndex = this.currentId ? this.menuItems.findIndex((p:MenuItem) => p.id === this.currentId) : this.menuItems.findIndex((p:MenuItem) => p.id === this.initialId);
		const nextIndex = currentIndex + 1;
		if (nextIndex < this.menuItems.length) {
			this.currentId = this.menuItems[nextIndex].id;
		} else {
			this.currentId = this.menuItems[0].id;
		}
		this.setTemporaryItem({ tempItem: this.menuItem });
	}

	/**
	 * Go to previous menu item
	 *
	 * @return {void}
	 */
	private prevMenuItem(): void {
		const currentIndex = this.currentId ? this.menuItems.findIndex((i:MenuItem) => i.id === this.currentId) : this.menuItems.findIndex((i:MenuItem) => i.id === this.initialId);
		const prevIndex = currentIndex - 1;
		if (prevIndex > -1) {
			this.currentId = this.menuItems[prevIndex].id;
		} else {
			this.currentId = this.menuItems[this.menuItems.length - 1].id;
		}
		this.setTemporaryItem({ tempItem: this.menuItem });
	}

	/**
	 * Add the temp item to the cart. Checks if the required options are filled before validating and adding to the array of items.
	 * * If we update the logic of adding the item to the cart, check if it impacts addItemToCart in CartUpsellItemList.vue
	 *
	 * @return {Promise}
	 */
	private async addItemToCart(): Promise<void> {
		this.processing = true;
		this.errorModalEvents = ['close'];
		this.errorModalShowSecondButton = false;
		this.errorModalButtons = i18n.t('menu.item_viewer.error_validation_dialog_buttons') as unknown as string[];

		// Don't allow adding items to the cart if the user is anonymous and the restaurant is generic catering
		if(this.checkIfLoginIsRequired()) {
			return void (this.processing = false);
		}

		if(this.requiredOptionsFilled) {

			// If the item is alcoholic and the legal check has not been checked yet
			// we don't allow adding that item to the cart until it is checked.
			if(this.legalCheck()) {
				let extraData = {
					special_instructions: this.specialInstructions,
					originalPrice: this.menuItem!.price,
					type: this.getExtraDataType(),
					orderDate: this.orderDate
				};
				try {
					// Verify, if there is a max items per cart limit, that adding an item
					// will not exceed that limit.
					if(!addToCartExceedLimit()) {
						await this.addToCart(extraData);
						this.$emit('item-added');
					}
					else {
						this.updateValidationError({message: this.$t('menu.item_viewer.error_item_limit_exceeded', { maxItemsPerCart: this.maxItemsPerCart})});
						this.errorModalOpened = true;
					}
				}
				catch {
					this.errorModalOpened = true;
				}
				finally {
					if(!this.errorModalOpened) {
						this.closeModal();
					}
				}
			}
		}
		else {
			this.showErrorMessages = true;
			alert(this.$t('menu.item_viewer.error_required_fields_unfilled'));
			setTimeout(() => {
				const requiredLabel = document.getElementsByClassName('red')[0] as HTMLElement;
				const requiredLabelStyle = window.getComputedStyle(requiredLabel);
				document.getElementsByClassName('menu-content')[0].scrollTo({ top: requiredLabel.offsetTop -
					requiredLabel.clientHeight - parseFloat(requiredLabelStyle['marginTop']!) -
					parseFloat(requiredLabelStyle['marginBottom']!) - 26, behavior: 'smooth' });
			}, 250);
		}
		this.processing = false;
	}

	/**
	 * Select the type property in the extraData object when adding an item to the cart.
	 *
	 * @return {string | undefined}
	 */
	private getExtraDataType(): string | undefined {
		if (this.menuItem?.lastOrderedOptions) {
			return 'reorder';
		}
		if (this.isFeaturedItem) {
			return 'addFeaturedItem';
		}
		if (this.isUpsellItem) {
			return 'addUpsellItem';
		}
		return undefined;
	}

	/**
	 * Display an error when adding an item to the cart, if ordering requires login and the user is anonymous
	 *
	 * @return {boolean}
	 */
	private checkIfLoginIsRequired(): boolean {
		if(this.isAnonymousUser && this.orderingRequiresLogin) {
			this.errorModalOpened = true;
			this.errorModalEvents = ['log-in', 'close'];
			this.errorModalButtons = [i18n.t('menu.item_viewer.generic_catering_warning_button'), i18n.t('menu.item_viewer.generic_catering_close_button')];
			this.errorModalShowSecondButton = true;
			this.updateValidationError({
				message: this.$t(`menu.item_viewer.${this.getOrderType(false).replace(/-/g, '_')}_warning`)
			});
			this.processing = false;
			return true;
		}
		return false;
	}

	/**
	 * Redirect user to log in
	 *
	 * @return {void}
	 */
	private redirectToLogin(): void {
		this.$router.push({ path: '/login', query: this.$route.query }).catch(() => {});
	}

	/**
	 * Set the required options filled boolean to whatever value
	 * is returned from the child's event after an option was
	 * selected/unselected
	 *
	 * @param {boolean} value
	 * @return {void}
	 */
	private setRequiredOptionsFilledBool(value: boolean): void {
		this.requiredOptionsFilled = value;
	}

	/**
	 * Check if the item contains alcohol to trigger the legal check
	 *
	 * @return {void}
	 */
	private legalCheck(): boolean {
		if(this.menuItem && this.menuItem.allergens) {
			if(this.menuItem.allergens.includes('Alcohol')) {
				if(this.legalChecked) {
					return true;
				}
				else {
					this.errorModalOpened = true;
					this.legalCheckRequired = true;
					this.updateValidationError({
						message: this.$t('menu.item_viewer.alcohol_legal_warning')
					});
					return false;
				}
			}
		}
		return true;
	}

	/**
	 * Close the error modal with a timeout to avoid edge and IE problems
	 * that would close both modals because of clickaway
	 *
	 * @return {void}
	 */
	private closeErrorModal(): void {
		setTimeout(() => {
			if (this.legalCheckRequired && this.legalChecked) {
				this.addItemToCart();
			}
			this.errorModalOpened = false;
			this.legalCheckRequired = false;
		}, 150);
	}

	/**
	 * Send close event to close the menu viewer
	 *
	 * @return {void}
	 */
	private closeModal(): void {
		this.$emit('close');
	}
}
