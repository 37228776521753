
import { Vue, Component, Prop } from 'vue-property-decorator';
import { Action, Getter } from 'vuex-class';
import { ValidationProvider } from 'vee-validate';
import { scrollIntoViewIfNeeded } from '../../../utils/helpers';
import CreditCardInput from './payment-info/CreditCardInput.vue';
import CostCenterInput from './payment-info/CostCenterInput.vue';
import PurchaseOrderInput from './payment-info/PurchaseOrderInput.vue';
import GooglePayIcon from '../../../assets/images/icons/google-pay-icon.svg?inline';
import ApplePayIcon from '../../../assets/images/icons/apple-pay-icon.svg?inline';
import CreditCardIcon from '../../../assets/images/icons/credit-card-icon.svg?inline';
import InvoiceIcon from '../../../assets/images/icons/invoice.svg?inline';
import CostCenterIcon from '../../../assets/images/icons/cost-center.svg?inline';
import PurchaseOrderIcon from '../../../assets/images/icons/purchase-order.svg?inline';
import '@/validation-rules';

const namespace: string = 'cart';

@Component<CheckoutPaymentInformation>({
	components: {
		ValidationProvider,
		CreditCardInput,
		CostCenterInput,
		PurchaseOrderInput,
		GooglePayIcon,
		ApplePayIcon,
		CreditCardIcon,
		InvoiceIcon,
		CostCenterIcon,
		PurchaseOrderIcon
	}
})
export default class CheckoutPaymentInformation extends Vue {
	@Prop({ type: Boolean, required: true, default: false }) private gPayReady!: boolean;
	@Prop({ type: Boolean, required: true, default: false }) private aPayReady!: boolean;
	@Action('updateCheckoutPaymentMethod', { namespace }) private updateCheckoutPaymentMethod!: (paymentMethod: string) => void;
	@Action('setIsUserSavingPaymentMethodOnCheckout', { namespace }) private setIsUserSavingPaymentMethodOnCheckout!: (savePaymentMethodOnCheckoutChecked: boolean) => void;
	@Getter('getCardInformation', { namespace }) private cardInformation!: CheckoutCardInfo;
	@Getter('getInvoiceInformation', { namespace }) private invoiceInformation!: boolean;
	@Getter('getCostCenterInformation', { namespace }) private costCenterInformation!: string | null;
	@Getter('getPurchaseOrderInformation', { namespace }) private purchaseOrderInformation!: string | null;
	@Getter('getPaymentMethod', { namespace }) private paymentMethod!: string;
	@Getter('isUserSavingPaymentMethodOnCheckout', { namespace }) private isUserSavingPaymentMethodOnCheckout!: boolean;
	@Getter('isGenericCatering', { namespace }) private isGenericCatering!: boolean;
	@Getter('isPreOrdering', { namespace: 'suites' }) private isPreOrdering!: boolean;
	@Getter('isEventDayOrdering', { namespace: 'suites' }) private isEventDayOrdering!: boolean;
	@Getter('isAnonymousUser', { namespace: 'auth' }) private isAnonymousUser!: boolean;
	@Getter('getRestaurantExtraPaymentMethods', { namespace: 'restaurant' }) private restaurantExtraPaymentMethods!: RestaurantExtraPaymentMethods[] | null | undefined;
	@Getter('isMarketplaceHub', { namespace: 'restaurant' }) private isMarketplaceHub!: boolean;
	@Getter('isMultipleMids', { namespace: 'restaurant' }) private isMultipleMids!: boolean;
	@Getter('genericCateringHideCreditCard', { namespace: 'restaurant' }) private genericCateringHideCreditCard!: boolean;

	private initComplete: boolean = false;
	private savePaymentMethodOnCheckout: boolean = false;
	private paymentCard: CheckoutCardInfo = {
		number: '',
		cvd: '',
		expiry_date: '',
		postal_code: '',
		type: ''
	};
	private invoice: boolean = false;
	private costCenter: string | null = null;
	private purchaseOrder: string | null = null;
	private paymentMethods: VueSelectOption[] = [];

	/**
	 * Display payment dropdown if there are multiple payment methods or if there is only one payment method and it's not credit card
	 *
	 * @return {boolean}
	 */
	private get displayPaymentDropdown(): boolean {
		return this.paymentMethods.length > 1 || (this.paymentMethods.length === 1 && this.paymentMethods[0].code !== 'credit_card');
	}

	/**
	 * On component creation, strip the payment methods that
	 * are not supported.
	 *
	 * @return {void}
	 */
	private async created(): Promise<void> {
		await this.initPaymentMethods();
		this.initComplete = true;

		if(this.paymentMethod === 'invoice') {
			this.setInvoice(true);
		}
	}

	/**
	 * Update payment information if there are any
	 *
	 * @return {void}
	 */
	private mounted(): void {
		if (this.invoiceInformation) {
			this.invoice = this.invoiceInformation;
		}
		if (this.costCenterInformation) {
			this.costCenter = this.costCenterInformation;
		}
		else if (this.purchaseOrderInformation) {
			this.purchaseOrder = this.purchaseOrderInformation;
		}
		else if (this.cardInformation) {
			this.paymentCard = this.cardInformation;
		}
		this.savePaymentMethodOnCheckout = this.isUserSavingPaymentMethodOnCheckout;
	}

	/**
	 * Initialize the payment methods
	 *
	 * @return {Promise<void>}
	 */
	private async initPaymentMethods(): Promise<void> {
		// We do not support apple/google pay for pre-orders, catering orders, or marketplace locations that have multiple mids
		if(!this.isPreOrdering && !this.isGenericCatering && (!this.isMarketplaceHub || this.isMarketplaceHub && !this.isMultipleMids)) {
			if(this.gPayReady) {
				this.paymentMethods.push({ label: 'Google Pay', code: 'google_pay' });
			}
			if(this.aPayReady) {
				this.paymentMethods.push({ label: 'Apple Pay', code: 'apple_pay' });
			}
		}
		this.paymentMethods.push({ label: this.$t('checkout.form.payment.credit_card_option'), code: 'credit_card' });

		// Add extra payment methods if available
		if(this.restaurantExtraPaymentMethods?.length && this.isGenericCatering) {
			this.restaurantExtraPaymentMethods.forEach((paymentMethod: RestaurantExtraPaymentMethods) => {
				this.paymentMethods.push({ label: paymentMethod.label, code: paymentMethod.type });
			});
		}

		// If there is only one payment method, this means that only
		// the CC is allowed, so we set the payment method as CC for the
		// input to show right away instead of having the user select it.
		if(this.paymentMethods.length <= 1) {
			if(!this.paymentMethod) {
				await this.setPaymentMethod('credit_card', true);
			}
		}

		// Remove credit card option for generic catering if the user is anonymous or if location is configured to not show the credit card option
		if (this.isGenericCatering && (this.genericCateringHideCreditCard || this.isAnonymousUser) ) {
			const ccIndex = this.paymentMethods.findIndex((method: VueSelectOption) => method.code === 'credit_card');

			if (ccIndex > -1) {
				this.paymentMethods.splice(ccIndex, 1);

				// If there is only one payment method is left, set it as the default
				if (this.paymentMethods.length === 1) {
					this.setPaymentMethod(this.paymentMethods[0].code, true);
				}
				else {
					this.setPaymentMethod('', true);
				}
			}
		}
	}

	/**
	 * Update payment method (GPay, APay, CC, other)
	 *
	 * @param {string} method
	 * @param {boolean} isDefault
	 * @return {Promise<void>}
	 */
	private async setPaymentMethod(method: string, isDefault: boolean = false): Promise<void> {
		this.costCenter = '';
		this.purchaseOrder = '';
		this.$emit('paymentMethodSelected');
		this.handleCCSelection(method, isDefault);
		this.handleInvoiceSelection(method);
		await this.updateCheckoutPaymentMethod(method);
	}

	/**
	 * Handle the credit card selection
	 *
	 * @param {string} method
	 * @param {boolean} isDefault
	 * @return {void}
	 */
	private handleCCSelection(method: string, isDefault: boolean): void {
		if(method === 'credit_card') {
			// check scrolling for purchase order, etc.
			if(!isDefault) {
				let checkoutDiv = document.getElementById('checkout-content')!;
				checkoutDiv.scrollTop = checkoutDiv.scrollHeight;
			}
		}
		else {
			this.paymentCard = {
				number: '',
				cvd: '',
				expiry_date: '',
				postal_code: '',
				type: ''
			};
			this.savePaymentMethodOnCheckout = false;
		}
	}

	/**
	 * Handle the invoice selection
	 *
	 * @param {string} method
	 * @return {void}
	 */
	private handleInvoiceSelection(method: string): void {
		method === 'invoice' ? this.setInvoice(true) : this.setInvoice(false);
	}

	/**
	 * Get the input label for the payment type
	 *
	 * @param {string} paymentMethod
	 * @return {string | null}
	 */
	private getInputLabel(paymentMethod: string): string | null {
		const paymentMethodObj = this.paymentMethods.find((method: VueSelectOption) => method.code === paymentMethod);
		return paymentMethodObj ? paymentMethodObj.label : null;
	}

	/**
	 * Set the payment card from the child's data and send to parent
	 *
	 * @param {CheckoutCardInfo} childPaymentCardData
	 * @return {void}
	 */
	private setPaymentCardInfo(childPaymentCardData: CheckoutCardInfo): void {
		this.paymentCard = childPaymentCardData;
		this.$emit('set-payment-card', this.paymentCard);
	}

	/**
	 * Set the invoice and send to parent
	 *
	 * @param {boolean} tempInvoice
	 * @return {void}
	 */
	private setInvoice(tempInvoice: boolean): void {
		this.invoice = tempInvoice;
		this.$emit('set-invoice', this.invoice);
	}

	/**
	 * Set the cost center from the child's data and send to parent
	 *
	 * @param {string} tempCostCenter
	 * @return {void}
	 */
	private setCostCenter(tempCostCenter: string | null): void {
		this.costCenter = tempCostCenter;
		this.$emit('set-cost-center', this.costCenter);
	}

	/**
	 * Set the purchase order from the child's data and send to parent
	 *
	 * @param {string} tempPurchaseOrder
	 * @return {void}
	 */
	private setPurchaseOrder(tempPurchaseOrder: string | null): void {
		this.purchaseOrder = tempPurchaseOrder;
		this.$emit('set-purchase-order', this.purchaseOrder);
	}

	/**
	 * Set the save payment method flag
	 *
	 * @param {boolean} savePaymentMethodFlag
	 * @return {void}
	 */
	private setSavePaymentMethodFlag(savePaymentMethodFlag: boolean): void {
		this.savePaymentMethodOnCheckout = savePaymentMethodFlag;
		this.updateSavePaymentMethod();
	}

	/**
	 * Emit event to parent to update the save payment card data property
	 *
	 * @return {void}
	 */
	private updateSavePaymentMethod(): void {
		this.setIsUserSavingPaymentMethodOnCheckout(this.savePaymentMethodOnCheckout);
	}

	/**
	 * Scroll to the bottom when opening a selector
	 *
	 * @param {string} elementId
	 * @return {void}
	 */
	private opened(elementId: string): void {
		setTimeout(() => {
			let contentDiv = document.querySelector(`#${elementId} .vs__dropdown-menu`);
			contentDiv && scrollIntoViewIfNeeded(contentDiv, { behavior: 'smooth', block: 'end', inline: 'start' });
		}, 100);
	}
}
