
import { Component, Prop, Vue } from 'vue-property-decorator';
import { Action, Getter } from 'vuex-class';
import { DateTime } from 'luxon';
import ChevronLeftIcon from 'vue-feather-icons/icons/ChevronLeftIcon';
import ChevronRightIcon from 'vue-feather-icons/icons/ChevronRightIcon';

const namespace: string = 'restaurant';

@Component<DateRangeSelector>({
	components: {
		ChevronLeftIcon,
		ChevronRightIcon
	}
})
export default class DateRangeSelector extends Vue {
	@Action('setDateRange', { namespace }) private setDateRange!: (dateRange: DateRange) => void;
	@Getter('getDateRange', { namespace }) private dateRange!: DateRange;
	@Getter('getZone', { namespace }) private zone!: string;
	@Getter('getOrderCutoffDate', { namespace }) private orderCutoffDate!: DateTime | null;
	@Getter('getMaxAdvancedOrderDays', { namespace }) private maxAdvancedOrderDays!: number;
	@Prop({ type: Number, required: false, default: 0 }) private screenWidth!: number;

	private get dateString(): string | void {
		if (this.dateRange?.start) {
			const date = DateTime.fromISO(this.dateRange.start).setLocale(this.$i18n.locale).toFormat(`${this.screenWidth < 768 ? 'LLL dd' : 'DD'}`)
			return this.$t(this.screenWidth < 768 ? 'menu.week_of_abbr' : 'menu.week_of', { date });
		}
	}

	/**
	 * Compare selected date with the cutoff date to determine if the previous button should be disabled
	 * If there is no cutoff compare with the current date
	 *
	 * @return {boolean}
	 */
	private get isPrevDisabled(): boolean {
		if (!this.dateRange.start) {
			return false;
		}
		const startOfWeek = DateTime.fromISO(this.dateRange.start).startOf('week');
		if (this.orderCutoffDate) {
			return startOfWeek <= this.orderCutoffDate.startOf('week');
		}
		return startOfWeek <= DateTime.now().setZone(this.zone).startOf('week');
	}

	/**
	 * Compare the start of next week with the advanced order limit date to determine if the next button should be disabled
	 *
	 * @return {boolean}
	 */
	private get isNextDisabled(): boolean {
		if (!this.dateRange.start) {
			return false;
		}

		// If there is no advanced order limit, the button should not be disabled
		if (!this.maxAdvancedOrderDays) {
			return false;
		}
		// Check if the start of the next week is after the advanced order limit date (today + maxAdvancedOrderDays)
		return DateTime.fromISO(this.dateRange.start).plus({ week: 1 }).startOf('week') > DateTime.now().setZone(this.zone).plus({ days: this.maxAdvancedOrderDays }).startOf('day')
	}

	/**
	 * Change the date range to the next or previous or week and update the query params
	 *
	 * @param {string} direction
	 * @return {void}
	 */
	private changeDateRange(direction: 'prev' | 'next'): void {
		if (this.dateRange.start === null) {
			return;
		}
		const start = DateTime.fromISO(this.dateRange.start).plus({ weeks: direction === 'prev' ? -1 : 1 });

		this.setDateRange({
			start: start.startOf('week').toISODate(),
			end: start.endOf('week').toISODate()
		});
		this.$router.replace({ query: { ...this.$route.query, selectedWeek: this.dateRange.start }}).catch(() => {});
	}
}
