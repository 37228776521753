
import { Vue, Component, Prop } from 'vue-property-decorator';
import { Getter } from 'vuex-class';
import { ValidationProvider } from 'vee-validate';
import { DateTime } from 'luxon';
import { findSupportedLocale } from '@/utils/i18n-helpers';
import { scrollIntoViewIfNeeded } from '@/utils/helpers';
import StarIcon from '../../../assets/images/icons/star.svg?inline';

const namespace: string = 'cart';

@Component<CustomQuestions>({
	components: {
		StarIcon,
		ValidationProvider
	}
})
export default class CustomQuestions extends Vue {
	@Getter('getCheckoutAnsweredQuestions', { namespace }) private checkoutAnsweredQuestions!: CheckoutCustomQuestion[] | null;
	@Getter('getTableNum', { namespace }) private tableNum!: string | null;
	@Getter('getPickupInformation', { namespace }) private pickupInformation!: CheckoutPickupInfo | null;
	@Getter('isPreOrdering', { namespace: 'suites' }) private isPreOrdering!: boolean;
	@Getter('getRestaurant', { namespace: 'restaurant' }) private restaurant!: Restaurant;
	@Prop({ type: Array, required: true, default: false }) private questions!: CustomQuestion[];

	private mutableQuestions: CustomQuestion[] = [];
	private timeOptions: { label: string; value: string; }[] = [];

	private get answeredQuestions(): CheckoutCustomQuestion[] {
		return this.mutableQuestions
			.filter((questionObj: CustomQuestion) => {
				if (questionObj.question_type === 'checkbox') {
					return 'checked' in questionObj;
				}
				if (questionObj.question_type === 'timepicker') {
					return questionObj.timeSelected !== '';
				}
				// Else, it is a Select One question
				return questionObj.answerObj && Object.keys(questionObj.answerObj).length;
			})
			.map(this.formatAnsweredQuestionObj);
	}

	private mounted(): void {
		this.mutableQuestions = this.questions.map((questionObj: CustomQuestion) => {
			const answeredQuestion: CheckoutCustomQuestion | undefined = this.checkoutAnsweredQuestions?.find((answeredQuestionObj) => answeredQuestionObj.question === questionObj.question);

			// If checkbox question, set the checked value whether it has been answered or not
			if (questionObj.question_type === 'checkbox') {
				return {
					...questionObj,
					checked: answeredQuestion?.answer === this.$t('checkout.form.custom_questions.checked')
				}
			}
			// Timepicker question
			if (questionObj.question_type === 'timepicker') {
				return {
					...questionObj,
					timeSelected: answeredQuestion?.answer as string || ''
				};
			}

			// If a non-checkbox question has been already answered, set the value to that answer
			if (answeredQuestion) {
				// Select One question
				const index = this.checkoutAnsweredQuestions?.findIndex((answeredQuestionObj: CheckoutCustomQuestion) => answeredQuestionObj.question === questionObj.question) as number;
				return {
					...questionObj,
					answerObj: {
						index,
						label: this.$options.filters?.localize(answeredQuestion, 'answer')
					}
				}
			}
			return { ...questionObj };
		});
		this.timeOptions = this.generateTimeOptions();
	}

	/**
	 * Called whenever there is a change of pickup date or time.
	 * Update the timepicker questions with the new time options.
	 * If possible, pre-select the timepicker answer.
	 *
	 * @param {boolean} [isFirstLoad=false]
	 * @return {void}
	 */
	public updateTimepickerQuestions(isFirstLoad: boolean = false): void {
		if (this.questions.some((questionObj: CustomQuestion) => questionObj.question_type === 'timepicker')) {
			const { dueByTime, dueByDate } = this.pickupInformation || {};
			this.timeOptions = this.generateTimeOptions(dueByDate, dueByTime);
			// If a pickup date and time were selected, set the timepicker answers to that date
			if (!isFirstLoad && dueByDate && dueByTime) {
				this.mutableQuestions.forEach((questionObj: CustomQuestion) => {
					if (questionObj.question_type === 'timepicker') {
						questionObj.timeSelected = '';
						this.updateQuestions();
					}
				});
			}
		}
	}

	/**
	 * Generate time options for the timepicker question.
	 * The options range from 12 hours before and 12 hours after the given date/time, in 15-minute intervals.
	 * If applicable, use the given pickup time as the starting point, otherwise uses the current time.
	 *
	 * @param {string} [pickupDateString]
	 * @param {string} [pickupTimeString]
	 * @return {{ label: string; value: string; }[]}
	 */
	private generateTimeOptions(pickupDateString?: string, pickupTimeString?: string): { label: string; value: string; }[] {
		let startTime: DateTime;
		const pickupDate: DateTime | undefined = pickupDateString ? DateTime.fromISO(pickupDateString) : undefined;
		const pickupTime: DateTime | undefined = pickupTimeString ? DateTime.fromFormat(pickupTimeString, this.$t('checkout.confirmation.time_format')) : undefined;
		let now: DateTime = DateTime.now();
		const roundedMinutes = Math.ceil(now.minute / 15) * 15;
		now = now.set({ minute: roundedMinutes });
		if (roundedMinutes === 60) {
			now = now.plus({ hours: 1 }).set({ minute: 0 });
		}

		const startDate = DateTime.fromObject({
			year: pickupDate?.year || now.year,
			month: pickupDate?.month || now.month,
			day: pickupDate?.day || now.day,
			hour: pickupTime?.hour || now.hour,
			minute: pickupTime?.minute || now.minute,
			second: 0,
			millisecond: 0
		});

		// Generate the time series
		startTime = startDate.minus({ hours: 12 });
		const timeSeries: { label: string; value: string; }[] = [];

		for (let i = 0; i < 24 * 4; i++) {
			timeSeries.push({
				label: startTime.toFormat(this.$t('checkout.form.takeout.timepicker_format')),
				value: startTime.toISO()
			});
			startTime = startTime.plus({ minutes: 15 });
		}

		return timeSeries;
	}

	/**
	 * Scroll to the selected pickup time in the timepicker dropdown.
	 * If there is no pickup time, scrolls to the current time.
	 *
	 * @param {number} index
	 * @return {void}
	 */
	private scrollToPickupTime(index: number): void {
		Vue.nextTick(() => {
			let { dueByTime } = this.pickupInformation || {};

			if (!dueByTime) {
				let now: DateTime = DateTime.now();
				const roundedMinutes = Math.ceil(now.minute / 15) * 15;
				now = now.set({ minute: roundedMinutes });
				if (roundedMinutes === 60) {
					now = now.plus({ hours: 1 }).set({ minute: 0 });
				}
				dueByTime = now.toFormat(this.$t('checkout.confirmation.time_format'));
			}

			const timeOptions = Array.from(document.querySelectorAll(`#custom-question-${index} .vs__dropdown-menu .vs__dropdown-option span`));
			const timeToScrollTo = timeOptions.find((timeOption) => timeOption.textContent === dueByTime);
			timeToScrollTo && scrollIntoViewIfNeeded(timeToScrollTo, { behavior: 'smooth', block: 'end', inline: 'start' });
		});
	}

	/**
	 * Return the localized options in an array of objects containing their indexes.
	 * This index is needed to get each localized answer when building the answeredQuestions array.
	 *
	 * @param {QuestionWithOptions} question
	 * @return {{ index: number; label: string; }[]}
	 */
	private formatDropdownOptions(question: QuestionWithOptions): { index: number; label: string; }[] {
		const localizedOptions: string[] | undefined = this.$options.filters?.localize(question, 'options');
		if (localizedOptions && localizedOptions.length) {
			const output = localizedOptions.map((questionOption: string, index: number) => {
				return {
					index,
					label: questionOption
				};
			});
			return output;
		}
		return question.options.map((questionOption: string, index: number) => {
			return {
				index,
				label: questionOption
			};
		});
	}

	/**
	 * Format the answered questions object depending of the question's type
	 *
	 * @param {CustomQuestion} questionObj
	 * @return {CheckoutCustomQuestion}
	 */
	private formatAnsweredQuestionObj(questionObj: CustomQuestion): CheckoutCustomQuestion {
		// Format basic non-localized question/answer
		let answer: string;
		if (questionObj.question_type === 'checkbox') {
			const defaultLocale = this.restaurant.locales?.find((locale: RestaurantLocale) => locale.is_default);
			const supportedLocale = defaultLocale?.locale_short ? findSupportedLocale('', defaultLocale.locale_short) : null;
			const translationKey = `checkout.form.custom_questions.${(questionObj).checked ? 'checked' : 'unchecked'}`;

			answer = supportedLocale ? this.$t(translationKey, supportedLocale.code) : this.$t(translationKey);
		}
		else if (questionObj.question_type === 'timepicker') {
			answer = questionObj.timeSelected;
		}
		else {
			const index = (questionObj as QuestionWithOptions).answerObj?.index as number;
			answer = (questionObj as QuestionWithOptions).options[index];
		}

		const answeredQuestion: CheckoutCustomQuestion = {
			question: questionObj.question,
			answer
		};

		// If there is localization, add the question and answer for each locale
		const localization: Localization = {};
		if (questionObj.localization && Object.keys(questionObj.localization).length) {
			for (const locale in questionObj.localization) {
				if (questionObj.question_type === 'checkbox') {
					const supportedLocale = findSupportedLocale('', locale);
					localization[locale] = {
						question: questionObj.localization[locale].question,
						answer: this.$i18n.t(`checkout.form.custom_questions.${(questionObj as QuestionWithCheckbox).checked ? 'checked' : 'unchecked'}`, supportedLocale!.code)
					};
				}
				// For timepicker questions, only the localized question is needed, the Menu Manager will handle the display of localized time
				else if (questionObj.question_type === 'timepicker') {
					localization[locale] = {
						question: questionObj.localization[locale].question
					};
				}
				else {
					const index = (questionObj as QuestionWithOptions).answerObj?.index as number;
					localization[locale] = {
						question: questionObj.localization[locale].question,
						answer: questionObj.localization[locale].options![index]
					};
				}
			}
			answeredQuestion.localization = localization;
		}

		return answeredQuestion;
	}

	/**
	 * Send event to update the questions' answers to the parent
	 *
	 * @return {void}
	 */
	private updateQuestions(): void {
		this.$emit('input', this.answeredQuestions);
	}
}
