import { DateTime } from "luxon";
import i18n from '@/i18n';

/**
 * Generate time series with the intervals gathered from the menu
 *
 * @param {number} interval
 * @param {(SchedulingRestrictionMenuAvailability | null)[]} weeklyAvailabilities
 * @param {string} dateSelected
 * @return {string[]}
 */
export function generateTimeSeries(dateSelected: string, weeklyAvailabilities: (SchedulingRestrictionMenuAvailability | null)[], interval: number = 30, userEventSuite?: EventSuite): string[] {
	// If intervals aren't set, we default to 30 minutes
	interval = interval || 30;
	const timeSeries: string[] = [];
	const selectedDate = DateTime.fromISO(dateSelected);
	const preOrderDeliveryStartDate: string | undefined = userEventSuite && userEventSuite.event.pre_order_delivery_time_setting.details.start_date!;
	const preOrderDeliveryEndDate: string | undefined  = userEventSuite && userEventSuite.event.pre_order_delivery_time_setting.details.end_date!;

	// Find the availability for the selected date
	const availability = weeklyAvailabilities.find(a => a && a.day_of_week === DateTime.fromISO(dateSelected).weekday);
	if (!availability) return [];

	// Parse the start and end times
	const [startHour, startMinute]: number[] = availability.start.split(':').map(Number);
	let [endHour, endMinute]: number[] = availability.end.split(':').map(Number);

	// If the end time is 00:00, set it to 24:00 instead
	if (endHour === 0 && endMinute === 0) {
		endHour = 24;
		endMinute = 0; // Avoid TS complaining about const
	}

	// Create DateTime objects for the start and end times
	let currentTime: DateTime = DateTime.fromObject({ year: 1970, month: 1, day: 1, hour: startHour, minute: startMinute, second: 0 });
	const endTime: DateTime = DateTime.fromObject({ year: 1970, month: 1, day: 1, hour: endHour, minute: endMinute, second: 0 });

	while (currentTime <= endTime) {
		timeSeries.push(currentTime.toFormat(i18n.t('checkout.form.takeout.timepicker_format')).padStart(4, '0'));
		currentTime = currentTime.plus({ minutes: interval });
	}

	// Filter out any times that are past the local time, and any times that are equal to the end time since
	// the user should not be able to order at the closing time.
	return timeSeries.filter(time => {
		const timeAsDateTime: DateTime = DateTime.fromFormat(time, i18n.t('checkout.form.takeout.timepicker_format')).set({ year: selectedDate.year, month: selectedDate.month, day: selectedDate.day });
		const tempTimeAsDateTime: string = timeAsDateTime.toFormat('T') === '00:00' ? '24:00' : timeAsDateTime.toFormat('T');
		const tempEndTime: string = endTime.toFormat('T') === '00:00' ? '24:00' : endTime.toFormat('T');

		// If preOrderDeliveryStartDate exists, exclude times that are before it
		if (preOrderDeliveryStartDate && timeAsDateTime < DateTime.fromISO(preOrderDeliveryStartDate)) {
			return false;
		}

		// If preOrderDeliveryEndDate exists, exclude times that are after it
		if (preOrderDeliveryEndDate && timeAsDateTime >= DateTime.fromISO(preOrderDeliveryEndDate)) {
			return false;
		}

		return timeAsDateTime >= DateTime.local() && tempTimeAsDateTime < tempEndTime;
	});
}

/**
 * Check if the time is within availability hours for the current day
 *
 * @param {DateTime} timeAsDateTime
 * @param {string} dateSelected
 * @param {string} time24
 * @param {(SchedulingRestrictionMenuAvailability | null)[]} weeklyAvailabilities
 * @param {string} overrideStartDate
 * @param {string} overrideEndDate
 * @return {boolean}
*/
function isWithinAvailabilityHoursFunc(timeAsDateTime: DateTime, dateSelected: string, time24: string, weeklyAvailabilities: (SchedulingRestrictionMenuAvailability | null)[], overrideStartDate?: string, overrideEndDate?: string): boolean {
	return weeklyAvailabilities.some(availability => {
		if (availability === null) {
			return false;
		}

		const availabilityStart = getAvailabilityStart(availability, dateSelected, overrideStartDate);
		const availabilityEnd = getAvailabilityEnd(availability, dateSelected, overrideEndDate);
		return (
			availability && (
				isWithinCurrentDayAvailability(timeAsDateTime, time24, availability, availabilityStart, availabilityEnd) ||
				isWithinPreviousDayAvailability(timeAsDateTime, time24, availability, availabilityStart, availabilityEnd)
			)
		);
	});
}

/**
 * Get the availability start time, if the pre order start date is the same as the availability day,
 * we want to use the start time of the pre order start date for the min selectable time. This of course
 * is only applicable for pre-orders, for takeout we're just going to grab the start time of the availability.
 *
 * Note: this could mean that the order would be refused if for some reason the menu for the pre-order is
 * not available for the start time of the event, but that's the suite operator's fault and do not require a fix.
 *
 * @param {SchedulingRestrictionMenuAvailability} availability
 * @param {string} dateSelected
 * @param {string} overrideStartDate
 * @return {string}
*/
function getAvailabilityStart(availability: SchedulingRestrictionMenuAvailability, dateSelected: string, overrideStartDate?: string): string {
	return overrideStartDate && DateTime.fromISO(overrideStartDate).startOf('day').equals(DateTime.fromISO(dateSelected).startOf('day'))
		? DateTime.fromISO(overrideStartDate).toFormat('HH:mm')
		: availability.start;
}

/**
 * Get the availability end time, if the pre order end date is the same as the availability day,
 * we want to use the end time of the pre order end date for the max selectable time. This of course
 * is only applicable for pre-orders, for takeout we're just going to grab the end time of the availability.
 *
 * Note: this could mean that the order would be refused if for some reason the menu for the pre-order is
 * not available for the end time of the event, but that's the suite operator's fault and do not require a fix.
 *
 * @param {SchedulingRestrictionMenuAvailability} availability
 * @param {string} dateSelected
 * @param {string} overrideEndDate
 * @return {string}
*/
function getAvailabilityEnd(availability: SchedulingRestrictionMenuAvailability, dateSelected: string, overrideEndDate?: string): string {
	return overrideEndDate && DateTime.fromISO(overrideEndDate).startOf('day').equals(DateTime.fromISO(dateSelected).startOf('day'))
		? DateTime.fromISO(overrideEndDate).toFormat('HH:mm')
		: availability.end;
}

/**
 * Allow selection if the time is within availability hours for the current day
 *
 * @param {DateTime} timeAsDateTime
 * @param {string} time24
 * @param {SchedulingRestrictionMenuAvailability} availability
 * @param {string} availabilityStart
 * @param {string} availabilityEnd
 * @return {boolean}
*/
function isWithinCurrentDayAvailability(timeAsDateTime: DateTime, time24: string, availability: SchedulingRestrictionMenuAvailability, availabilityStart: string, availabilityEnd: string): boolean {
	return timeAsDateTime.weekday === availability.day_of_week && (time24 >= availabilityStart && time24 < (availabilityEnd > availabilityStart && availabilityEnd != '00:00' ? availabilityEnd : '24:00'));
}

/**
 * Allow selection if the time is within availability hours for the previous day if those hours extend past midnight
 *
 * @param {DateTime} timeAsDateTime
 * @param {string} time24
 * @param {SchedulingRestrictionMenuAvailability} availability
 * @param {string} availabilityStart
 * @param {string} availabilityEnd
 * @return {boolean}
*/
function isWithinPreviousDayAvailability(timeAsDateTime: DateTime, time24: string, availability: SchedulingRestrictionMenuAvailability, availabilityStart: string, availabilityEnd: string): boolean {
	return timeAsDateTime.weekday === ((availability.day_of_week + 1) % 7) && availabilityEnd <= availabilityStart && (time24 < availabilityEnd);
}

/**
 * We need to add the interval time and the required notice to the date selected to
 * get the minimum time for the order.
 *
 * @param {string} dateSelected
 * @param {number} requiredNotice
 * @param {number} orderInterval
 * @return {string}
 */
export function addNoticeAndIntervalTimeToCurrentDate(dateSelected: string, requiredNotice: number, orderInterval: number): string {
	return DateTime.fromISO(dateSelected!).plus({ minutes: (requiredNotice + orderInterval) }).toISO()!;
}

/**
 * Get the minimum time for the order. We need to add the interval time and the required notice to the date selected.
 *
 * @param {(SchedulingRestrictionMenuAvailability | null)[]} weeklyAvailabilities
 * @param {HolidayHours[]} holidayHours
 * @param {string} dateSelected
 * @param {boolean} userAlreadySelectedDate
 * @param {DateTime | null} orderCutoffDate
 * @param {EventSuite | null} userEventSuite
 * @return {{ disabledDays: number[], date: string, minDate: Date | null }}
 */
export function setDisabledDaysForDatePicker(weeklyAvailabilities: (SchedulingRestrictionMenuAvailability | null)[], holidayHours: HolidayHours[], dateSelected: string, userAlreadySelectedDate: boolean, orderCutoffDate: DateTimeType | null, userEventSuite?: EventSuite | null): { disabledDays: number[], holidayClosedDates: Date[], date: string, minDate: Date | null } {
	const disabledDays: number[] = [];
	let minDate: Date | null = orderCutoffDate?.toJSDate() || null;
	let holidayClosedDates: Date[] = [];

	// If the selected date is before the order cutoff date, we set the selected date to the order cutoff date
	if (orderCutoffDate && orderCutoffDate > DateTime.fromISO(dateSelected)) {
		dateSelected = orderCutoffDate.toISO();
	}

	for (let index = 0; index < weeklyAvailabilities.length; index++) {
		dateSelected = checkIfDayHasAvailability(index, weeklyAvailabilities, holidayHours, dateSelected, userAlreadySelectedDate, userEventSuite);

		if (!weeklyAvailabilities[index]) {
			disabledDays.push((index + 1) % 7);
		}
	}

	if (holidayHours && holidayHours.length > 0) {
		holidayClosedDates = holidayHours.reduce((acc: Date[], holiday: HolidayHours) => {
		   if (holiday.full_day) {
			   acc.push(DateTime.fromISO(holiday.date).toJSDate());
		   }
		   return acc;
	   }, []);
	}

	// If there are availabilities on the the order cutoff date, we set the min date to the previous day
	if (orderCutoffDate && isWithinAvailabilityHoursFunc(orderCutoffDate, dateSelected, orderCutoffDate.toFormat('T'), weeklyAvailabilities)) {
		minDate = orderCutoffDate.minus({ days: 1 }).toJSDate();
	}

	return { disabledDays, holidayClosedDates, date: dateSelected, minDate };
}

/**
 * Check if the day has availability, if not we increment the date by one.
 *
 * @param {number} index
 * @param {(SchedulingRestrictionMenuAvailability | null)[]} weeklyAvailabilities
 * @param {HolidayHours[]} holidayHours
 * @param {string} dateSelected
 * @param {boolean} userAlreadySelectedDate
 * @param {EventSuite | null} userEventSuite
 * @return {string}
 */
export function checkIfDayHasAvailability(index: number, weeklyAvailabilities: (SchedulingRestrictionMenuAvailability | null)[], holidayHours: HolidayHours[], dateSelected: string, userAlreadySelectedDate: boolean, userEventSuite?: EventSuite | null): string {
	const isHoliday = holidayHours?.find((holiday: HolidayHours) => dateSelected.includes(holiday.date));
	if(isHoliday) {
		return DateTime.fromISO(dateSelected).set({ hour: 0, minute: 0 }).plus({ day: 1 }).toISO()!;
	}

	if(weeklyAvailabilities[index] && weeklyAvailabilities[index]!.day_of_week === DateTime.fromISO(dateSelected).weekday) {
		const orderTime = DateTime.fromISO(dateSelected).toFormat('T');

		// If this is the first time we get the current date
		if(!userAlreadySelectedDate) {
			// If the end date is the same as the current date, we use the end time from the pre order delivery time setting (if applicable)
			const endDate: DateTime | null | undefined = userEventSuite && DateTime.fromISO(userEventSuite!.event.pre_order_delivery_time_setting.details.end_date!);
			let endOfDayTime = endDate && endDate.startOf('day').equals(DateTime.fromISO(dateSelected).startOf('day')) ? endDate.toFormat('HH:mm') : weeklyAvailabilities[index]!.end;

			// If the end time is overlapping 12AM mark. We increment the value of hours and numbers with the start time.
			if(endOfDayTime < weeklyAvailabilities[index]!.start) {
				const [endHours, endMinutes] = endOfDayTime.split(':').map(Number);
				const [startHours, startMinutes] = weeklyAvailabilities[index]!.start.split(':').map(Number);
				endOfDayTime = `${endHours + startHours}:${endMinutes + startMinutes}`;
			}

			// If the order time is later than the end time, we push the date +1
			if(orderTime > endOfDayTime && weeklyAvailabilities[index]!.end != '00:00') {
				return DateTime.fromISO(dateSelected).set({ hour: 0, minute: 0 }).plus({ day: 1 }).toISO()!;
			}
		}
	}
	return dateSelected!;
}

/**
 * Get the next enabled date based on the disabled days. If the selected date is disabled
 * we will increment the date until we find an enabled date.
 *
 * @param {string} selectedDate
 * @param {number[]} disabledDays
 * @param {Date[]} holidayClosedDates
 * @param {string} zone
 * @return {string}
 */
export function findNextEnabledDate(selectedDate: string, disabledDays: number[], holidayClosedDates: Date[], zone: string): string {
	let enabledDate = false;
	const selectedDateTime = DateTime.fromISO(selectedDate);
	while (!enabledDate) {
		disabledDays.forEach(disabledDay => {
			if (DateTime.fromISO(selectedDate).weekday === (disabledDay === 0 ? 7 : disabledDay)) {
				selectedDate = DateTime.fromISO(selectedDate).plus({ days: 1 }).toISO()!;
			}
		});

		if (holidayClosedDates.find((date: Date) => {
			return DateTime.fromJSDate(date).setZone(zone).hasSame(selectedDateTime, 'day')
		})) {
			selectedDate = DateTime.fromISO(selectedDate).plus({ days: 1 }).toISO()!;
		}

		if (!disabledDays.includes(DateTime.fromISO(selectedDate).weekday % 7) && !holidayClosedDates.find((date: Date) => DateTime.fromJSDate(date).hasSame(selectedDateTime, 'day'))) {
			enabledDate = true;
		}
	}

	return selectedDate;
}
/**
 * When opening the time dropdown, checks every time interval if it is
 * selectable or not based on required_notice, current time, intervals,
 * availabilities and more.
 * TODO update most of the complexity to have it outside of the selectable method from the v-select component.
 *
 * @param {string} time
 * @param {string} date
 * @param {number} requiredNotice
 * @param {DateTime[]} maxedOutIntervals
 * @param {(SchedulingRestrictionMenuAvailability | null)[]} weeklyAvailabilities
 * @param {DateTime | null} orderCutoffDate
 * @param {EventSuite} userEventSuite
 * @return {boolean}
 */
export function isTimeSelectable(time: string, date: string, requiredNotice: number, maxedOutIntervals: DateTime[], weeklyAvailabilities: (SchedulingRestrictionMenuAvailability | null)[], orderCutoffDate: DateTime | null, userEventSuite?: EventSuite) {
	const currentDateTime: DateTime = orderCutoffDate || DateTime.local();
	const timeAsDateTime: DateTime = DateTime.fromFormat(`${date.split('T')[0]} ${time}`, i18n.t('checkout.form.takeout.timepicker_selectable_format'));
	const timeIn24Format: string = timeAsDateTime.toFormat('T');
	const preOrderDeliveryStartDate: string |  undefined = userEventSuite && userEventSuite.event.pre_order_delivery_time_setting.details.start_date!;
	const preOrderDeliveryEndDate: string | undefined  = userEventSuite && userEventSuite.event.pre_order_delivery_time_setting.details.end_date!;

	const isNotMaxedOut: boolean = !maxedOutIntervals.some(interval => timeAsDateTime.equals(interval));
	const isAfterCurrentTime: boolean = timeAsDateTime > currentDateTime;
	const isWithinAvailabilityHours: boolean = isWithinAvailabilityHoursFunc(timeAsDateTime, date, timeIn24Format, weeklyAvailabilities, preOrderDeliveryStartDate, preOrderDeliveryEndDate);
	const isNoticeLongEnough: boolean = !(requiredNotice && currentDateTime.plus({ minutes: requiredNotice }) > timeAsDateTime);

	return isNotMaxedOut && isAfterCurrentTime && isWithinAvailabilityHours && isNoticeLongEnough;
}